<script>import { Button, Grid, Route, navigate, Modal, api } from "duo-kit";
import { teams } from "./store.js";
import ReportingSingle from "./reporting-single.svelte";
let selectedteam = [];
let selected = [];
let teammembers = [];
let loading = false;

async function getTeam() {
  let id = selectedteam[0].id;
  teammembers = (await api.load({
    url: `/duocms/api/tracker/teams/${id}`
  })).data.users;
}

async function load() {
  loading = true;
  await teams.load();

  if ($teams.length > 0 && !selectedteam.length) {
    selectedteam[0] = $teams[0];
    getTeam();
  }

  loading = false;
}

function showUser() {
  navigate("/reporting/team/" + selected[0].user_id);
}

load();</script>

{#if loading || $teams.length > 0}
  <div class="row">
    <div class="col-xs-12 col-sm-3">
      <h3>Your PSE Teams</h3>
      <div style="height:500px">
        <Grid 
          rowheight={30} data={$teams} 
          bind:selected={selectedteam}
          on:click={getTeam}
          on:dblclick={()=> navigate(`/pseteams/${selectedteam[0].id}/rename`)}
          columns={[
            {label:"ID",field:"id",width:1},
            {label:"Name",field:"name",width:5},
          ]}
        > 

        <div slot="row" let:item>
          <div class="cell" style="flex:1" >{item.id}</div>
          <div class="cell" style="flex:5" >{item.name}</div>
        </div> 

        <div slot="footer" let:data>
          {data.length} {data.length == 1 ? 'Item' : 'Items'} {selectedteam.length ? "( "+selectedteam.length+" selected )" : ""} 
        </div>
        </Grid>
      </div>
    </div>
    <div class="col-xs-12 col-sm-9">
      <h3>Team Members</h3>
      <div style="height:500px">
        <Grid 
          rowheight={30} data={teammembers} 
          bind:selected
          on:click={showUser}
          columns={[
            {label:"First Name",field:"first_name",width:5},
            {label:"Surname",field:"surname",width:5},
            {label:"Email",field:"email",width:5},
            {label:"Type",field:"type",width:2}
          ]}
        > 
    
        <div slot="row" let:item>
          <div class="cell" style="flex:5" >{item.first_name || ""}</div>
          <div class="cell" style="flex:5" >{item.surname || ""}</div>
          <div class="cell" style="flex:5" >{item.email || item.invite_email}</div>
          <div class="cell" style="flex:2" >{item.type}</div>
        </div> 
    
        <div slot="footer" let:data>
          {data.length} {data.length == 1 ? 'Item' : 'Items'} {selected.length ? "( "+selected.length+" selected )" : ""} 
        </div>
        </Grid>
      </div>
    </div>
  </div>

  <Route path=":user_id" let:params>
    <Modal heading={selected.length && (selected[0].first_name || selected[0].surname ) ? `${selected[0].first_name || "" } ${selected[0].surname || ""}` : 'User Report'} open on:closed={()=> navigate("/reporting/team") } style="width:90%">
      <ReportingSingle userid={params.user_id} editable={true} />
    </Modal>
  </Route>
{:else}
  <br>
  <div class="alert alert-info">
    <h4>Sorry</h4>
    <p>You currently don't have any teams.</p>
    <a class="btn btn-default btn-sm" href="#!/pseteams/">Click here to add some</a>
  </div>
{/if}