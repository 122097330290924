<script>import { Grid, api, Search } from "duo-kit";
let resources_id = null;
let resources = [];
let filtered = [];
let loading = false;
export let id;

async function loadResources(id) {
  if (resources_id != id) {
    resources_id = id;
    resources = [];
    loading = true;
    resources = (await api.load({
      url: `/duocms/api/tracker/resources/${resources_id}`
    })).data;
    loading = false;
  }
}

$: loadResources(id);</script>

<div class="resources">
  <div class="searchbox" class:hidden={resources.length < 4}>
    <Search placeholder="Search Resources" bind:data={resources} bind:filtered={filtered} />
  </div>


  <div style="height:300px">
    <Grid 
      rowheight={55} data={filtered} 
      columns={[
        {label:"Type",field:"type",width:2},
        {label:"Resource",field:"resource",width:8},
        {label:"Reference",field:"reference",width:3},
        {label:"Link",field:"link",width:1}
      ]}
    >
      <div slot="row" style="width:100%" let:item>
        <div class="cell" style="flex:2;white-space:pre-wrap;text-overflow: ellipsis;height:60px;overflow:hidden;" >{item.type}</div>
        <div class="cell" style="flex:8;white-space:pre-wrap;text-overflow: ellipsis;height:60px;overflow:hidden;overflow-wrap: break-word;" >{item.resource}</div>
        <div class="cell" style="flex:3;white-space:pre-wrap;text-overflow: ellipsis;height:60px;overflow:hidden; overflow-wrap: break-word;" >{item.reference}</div>
        <div class="cell" style="flex:1" >{#if item.link}<a class="btn btn-xs btn-primary" href={item.link} target="_blank">Open</a>{/if}</div>
      </div> 
      <div slot="footer" let:data>
        {#if loading}
          <span class="fa fa-refresh fa-spin" />  Loading Resources
        {:else}
          {data.length} Items
        {/if}
      </div>
    </Grid>
  </div>
</div>

<style>
  .resources{
    position:relative;
  }
  .searchbox{
    position: absolute;
    top:-58px;
    right:-5px;
  }

</style>