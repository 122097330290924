
    var riot = require('riot')
    
riot.tag2('flexipass', '<yield></yield>', '.dynamicList .prebook { font-size: 120%; }', '', function(opts) {
var interval;

this.validemail = false;

this.emailerror = null;

this.formerror = null;

this.domains = "";

this.sendto = "";

this.eventlist = [];

interval = setInterval((function(_this) {
  return function() {
    if (document.querySelector('#prebooklist')) {
      _this.pbl = document.querySelector('#prebooklist');
      return clearInterval(interval);
    }
  };
})(this), 100);

this.on('mount', (function(_this) {
  return function() {
    _this.domains = _this.opts.domains.split("\n") || "nafems.org";
    _this.sendto = _this.opts.sendto.split("\n") || "";
    _this.domainstring = JSON.stringify(_this.domains);
    _this.sendtostring = JSON.stringify(_this.sendto);
    return _this.update();
  };
})(this));

this.checkEmail = (function(_this) {
  return function() {
    var idx;
    idx = _this.email.value.indexOf("@");
    if (idx > -1 && (_this.domains.indexOf(_this.email.value.slice(idx + 1)) > -1)) {
      _this.emailerror = null;
      return _this.validemail = true;
    } else {
      _this.emailerror = "You email address is not valid for this booking";
      return _this.validemail = false;
    }
  };
})(this);

this.validateForm = (function(_this) {
  return function(e) {
    var events;
    e.preventDefault();
    _this.formerror = null;
    _this.eventlist = [];
    _this.eventstr = "";
    events = _this.pbl.querySelectorAll('input:checked');
    if (events.length) {
      [].forEach.call(events, function(ev) {
        return _this.eventlist.push({
          title: ev.dataset.title,
          path: ev.dataset.path,
          date: ev.dataset.date
        });
      });
      _this.checkEmail();
      if (_this.validemail) {
        _this.eventstr = JSON.stringify(_this.eventlist);
        _this.update();
        prebookform.submit();
        return true;
      }
    } else {
      _this.formerror = "You have not selected any events";
    }
    return false;
  };
})(this);
});
    
  