<script>import { Formrow, Modal, Button, api, navigate, Route, Grid, toasterStore } from "duo-kit";
import { createEventDispatcher } from "svelte";
import { autoheight } from "duo-kit/src/shared/actions.js";
export let data = {};
export let open = false;
export let parent = "";
export let route;
let resources = [];
let competence_description;
let competence = {};
let loading = false;
let selected = [];
let fields = ["type", "code", "resource", "reference", "link"];
let dispatch = createEventDispatcher();

function fixresize(open) {
  // hack to update textarea height
  competence = { ...data
  }; // copy here so we don't change original

  if (open && competence_description) {
    setTimeout(() => {
      competence_description.dispatchEvent(new Event('input'));
    }, 100);
  }
}

async function save() {
  let savedata = Object.assign({}, competence);
  if (savedata.id == "new") delete savedata.id;
  if (resources) savedata.resources = resources;
  if (data.teacharea) delete savedata.resources;
  let res = (await api.save({
    url: `/duocms/api/tracker/competencies`,
    data: savedata
  })).data;
  if (res) competence = res;

  for (let key of Object.keys(competence)) {
    // copy back over original after save to update 
    data[key] = competence[key];
  }

  dispatch("saved", data);
  open = null;
}

async function loadResources(id) {
  loading = true;
  resources = (await api.load({
    url: `/duocms/api/tracker/resources/${id}`
  })).data;
  loading = false;
}

async function deleteRows() {
  resources = resources.filter(r => !selected.includes(r));
} // pushing the copied rows into the clipboard in an excel friendly format


async function copyRows(event) {
  if (event && document.getSelection().toString() == "") {
    // if copying empty text, use row copier
    event.preventDefault();
  }

  if (!selected.length) return;
  let clipboard = "";
  selected.forEach(row => {
    clipboard += `${row.type || ""}\t${row.code || ""}\t${row.resource || ""}\t${row.reference || ""}\t${row.link || ""}\n`;
  });

  try {
    await window.navigator.clipboard.writeText(clipboard);
  } catch (err) {
    // clipboard needs https to work
    toasterStore.add({
      title: "Sorry",
      type: "danger",
      message: "It was not possible to copy those rows to the clipboard"
    });
  }
}

async function addRow() {
  resources = resources.concat({});
} // intercepts paste into input and parses a table pasted into the correct cells


function pasting(e, item) {
  let text = e.clipboardData.getData('Text');
  let rows = text.split(/\n|\r/).filter(row => row.length).map(row => row.split(/\t/));
  let isTable = rows.every(row => row.length == rows[0].length); // isTable? - ie does it have the same number of tabs on each line?

  if (isTable) {
    e.preventDefault();
    let xoffset = fields.indexOf(e.target.name);
    let yoffset = resources.indexOf(item);
    rows.forEach((row, row_idx) => {
      if (!resources[row_idx + yoffset]) resources[row_idx + yoffset] = {};
      row.forEach((col, col_idx) => {
        if (fields[col_idx + xoffset]) {
          resources[row_idx + yoffset][fields[col_idx + xoffset]] = col;
        }
      });
    });
  }
}

function updateItem(name, item, e) {
  resources.forEach((r, idx) => {
    if (resources[idx] == item) {
      resources[idx][name] = e.target.value;
    }
  });
}

if (data.id != "new") {
  loadResources(data.id);
} else {
  resources = [];
}

$: fixresize(open);</script>

<svelte:window on:copy={copyRows} />

<Modal bind:open={open} style="width:90%;min-height:90%;" on:closed={()=> navigate(parent)} heading="Edit Competence" >
 
  <div class="row">
    <div class="col-xs-12">
      <ul class="nav nav-tabs" style="margin:10px 0;">   
        <li role="presentation" class:active={route && route == `${parent}/edit` }>
          <a href={`#!${parent}/edit`}>{data.teacharea ? 'Technical Area' : 'Competence Statement'}</a>
        </li>
        {#if !data.teacharea}
          <li role="presentation" class:active={route && route.startsWith(`${parent}/edit/resources`)}>
            <a href={`#!${parent}/edit/resources`} >Resource References</a>
          </li>
        {/if}
      </ul>
    </div>
  </div>
  <Route path="" exact>
    <div class="editform">
      {#if data.teacharea}
        <label for="competence_description">Description</label>
        <textarea id="competence_description" use:autoheight type="textarea" bind:value={competence.teacharea} bind:this={competence_description}></textarea>
        <Formrow label="Title" type="text" bind:value={competence.statement} />
        <Formrow label="Code" type="text" bind:value={competence.code} />
      {:else}
        <label for="competence_description">Competence Statement</label>
        <textarea id="competence_description" use:autoheight type="textarea" bind:value={competence.statement} bind:this={competence_description}></textarea>
        <br>
        <label for="competence_threshold">Threshold Statement</label>
        <textarea id="competence_threshold" use:autoheight type="textarea" bind:value={competence.threshold} ></textarea>
        <Formrow label="Code" type="text" bind:value={competence.code} />
        <Formrow label="Category" type="input" bind:value={competence.area} list="pse-categories" ></Formrow>
        <datalist id="pse-categories">
          <option value="Analysis">
          <option value="Application">
          <option value="Comprehension">
          <option value="Evaluation">
          <option value="Knowledge">
          <option value="Pre-Requisites">
          <option value="Synthesis">
        </datalist>
        <Formrow label="NAFEMS Level" type="select" bind:value={competence.level}>
          <option>Please Choose</option>
          <option value="Advanced">Advanced</option>
          <option value="Standard">Standard</option>
        </Formrow>
      {/if}
    </div>
  </Route>

  <Route path="resources" exact>
    <div class="resources">
      <div class="toolbar">
        <Button on:click={addRow}>Add Row</Button>
        <Button disabled={selected.length==0} on:click={deleteRows} >Delete Row</Button>
        <Button disabled={selected.length==0} on:click={copyRows}>Copy Rows</Button>
      </div>
      <div class="gridarea">
        <Grid
          rowheight={50} data={resources} 
          multiselect
          bind:selected={selected}
          columns={[
            {label:"Type",field:"type",width:1},
            {label:"Code",field:"code",width:1},
            {label:"Resource",field:"resource",width:5},
            {label:"Reference",field:"reference",width:2},
            {label:"Link",field:"link",width:3}
          ]}
        >
          <div slot="row" style="width:100%" let:item>
            <div class="cell" style="flex:1" ><input type="text" on:copy={copyRows} on:paste={(e)=> pasting(e,item)} name="type" value={item.type||""} on:input={updateItem.bind(null,"type",item)} /></div>
            <div class="cell" style="flex:1" ><input type="text" on:copy={copyRows} on:paste={(e)=> pasting(e,item)} name="code" value={item.code||""} on:input={updateItem.bind(null,"code",item)} /></div>
            <div class="cell" style="flex:5;"><textarea type="text" on:copy={copyRows} on:paste={(e)=> pasting(e,item)} name="resource" value={item.resource||""} on:input={updateItem.bind(null,"resource",item)}></textarea></div>
            <div class="cell" style="flex:2;"><input type="text" on:copy={copyRows} on:paste={(e)=> pasting(e,item)} name="reference" value={item.reference||""} on:input={updateItem.bind(null,"reference",item)}/></div>
            <div class="cell" style="flex:3"><input  type="text" on:copy={copyRows} on:paste={(e)=> pasting(e,item)} name="link" value={item.link||""}  on:input={updateItem.bind(null,"link",item)}/></div>
          </div> 
          <div slot="footer" let:data>
            {#if loading}
              <span class="fa fa-refresh fa-spin" />  Loading Resources
            {:else}
              {data.length} Items
            {/if}
          </div>
        </Grid>
      </div>
    </div>
  </Route>

  <div slot="toolbar" class="pull-right">
    <Button mode="default" on:click={()=> open=false} >Close</Button>
    <Button mode="primary" on:click={save}>Save</Button>
  </div>
</Modal>

<style>

.editform textarea{
  display:block;
  width:100%;
  padding:15px;
  border:1px dashed #ccc;
}

.resources{
  display:flex;
  flex-direction: column;
  height:calc(100vh - 250px);
}

.gridarea{
  flex:1 1 1px;
  overflow:hidden;
}

.cell{
    position:relative;
  }
.cell input[type=text],.cell textarea{
  border:0px;
  outline:1px solid rgba(0,0,0,0.1);
  font-size:12px;
  display:block;
  padding:4px 5px;
  color:#222;
  background:rgba(255,255,255,0.8);
  position: absolute;
  top:1px;
  left:1px;
  right:1px;
  bottom:1px;
  width:100%;
  resize: none;
}
.cell input[type=text]:focus,.cell textarea:focus{
  outline:0px;
  background:rgba(255,255,255,0.9)
}

</style>