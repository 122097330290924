
    var riot = require('riot')
    
riot.tag2('category', '<div class="form-group"> <select if="{options.length}" onchange="{handleSelect}" value="{selected}" class="form-control"> <option value="">All {opts.level==0 ? \'Categories\' : opts.level==1 ? \'Sub-Categories\' : \'\'}</option> <option each="{options}" value="{id}">{name}</option> </select> </div>', '', '', function(opts) {
var categories, getQueryVariable;

this.on("mount", function() {
  return this.selected = opts.value;
});

this.handleSelect = (function(_this) {
  return function(e) {
    _this.selected = e.target.value;
    return opts.onselect(opts.level + 1, _this.selected);
  };
})(this);

getQueryVariable = function(variable) {
  var val;
  val = "";
  window.location.search.substring(1).split('&').forEach(function(paramVal) {
    var pair;
    pair = paramVal.split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return val = decodeURIComponent(pair[1]);
    }
  });
  return val;
};

if (opts.categories) {
  this.options = opts.categories.filter(function(row) {
    return +row.parent_id === +opts.parent;
  });
  categories = getQueryVariable('catagories').split("+");
  this.options.forEach((function(_this) {
    return function(option) {
      if (categories.indexOf(option['id']) > -1) {
        _this.selected = option['id'];
        return setTimeout(function() {
          return opts.onselect(opts.level + 1, _this.selected);
        });
      }
    };
  })(this));
}
});
    
  