
    var riot = require('riot')
    
riot.tag2('community-checkbox', '<div if="{user &amp;&amp; user.id}" class="row"> <div class="col-xs-12"> <button if="{!user.community_id_created}" onclick="{save}" __disabled="{saving}" class="btn btn-primary"> <span show="{saving}" class="fa fa-refresh fa-spin"> </span> Request Access</button> <div if="{user.community_id_created}" class="alert alert-info"> <p>Access has been approved, please check your email for further instructions or</p> <p> <a href="https://nafems-community.force.com/learning/login/" class="btn btn-primary">Click here to login</a></p> </div> </div> </div>', '', '', function(opts) {
var profileApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

profileApi = new resource('/duocms/api/users/me', true);

this.on("mount", function() {
  this.saving = false;
  return profileApi.get((function(_this) {
    return function(user1) {
      _this.user = user1;
      return _this.update();
    };
  })(this));
});

this.save = (function(_this) {
  return function() {
    var user;
    _this.update({
      saving: true
    });
    user = {
      community_id_created: true
    };
    return profileApi.save(user, function(user1) {
      _this.user = user1;
      _this.saving = false;
      return _this.update();
    });
  };
})(this);
});
    
  