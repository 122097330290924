<script>import { Modal, Button } from "duo-kit";
let show = false;</script>

<div class="toolbar">
  <Button mode="primary" on:click={()=> show=true }>View Details</Button>
</div>
<Modal bind:open={show} style="max-width:min(90%,700px);">
  <slot></slot>
  <div slot="toolbar" class="toolbar">
    <Button mode="default" on:click={()=> show=false }>Close</Button>
  </div>
</Modal>

<style>
  .toolbar{
    text-align:right;
  }
</style>