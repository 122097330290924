
    var riot = require('riot')
    
riot.tag2('submit_details', '<raw content="{opts.opts.submit_details}"></raw> <form class="form-horizontal"> <div class="col-sm-12"> <formcheckbox width1="0" width2="12" label="I confirm that I will abide by the obligations of a Professional Simulation Engineer" type="checkbox" name="confirm1" onupdate="{handleUpdate}" isrequired></formcheckbox> </div> <div class="col-sm-12"> <h3>Referee Access to Information</h3> <p> The referees you have included will be sent a copy of your application via email, with your personal details removed (aside from your name). This will allow us to confirm that the details of your application are accurate. </p> <formcheckbox width1="0" width2="12" label="Please tick this box to confirm you give your consent for this" type="checkbox" name="confirm2" onupdate="{handleUpdate}" isrequired></formcheckbox> </div> <button type="button" onclick="{handleBack}" class="btn btn-warning">Back</button> <button onclick="{handleSubmit}" class="btn btn-success">Submit Application</button> </form>', '', '', function(opts) {
this.submit = {};

this.handleUpdate = (function(_this) {
  return function(val) {
    return _this.submit[val.name] = val.value;
  };
})(this);

this.handleSubmit = function() {
  if (this.submit['confirm1'] && this.submit['confirm2']) {
    return opts.submit();
  } else if (!this.submit['confirm1']) {
    return this.tags['confirm1'].tags['formgroup'].trigger('validate');
  } else if (!this.submit['confirm2']) {
    return this.tags['confirm2'].tags['formgroup'].trigger('validate');
  }
};

this.handleBack = function(e) {
  return opts.back();
};
});
    
  