
    var riot = require('riot')
    
riot.tag2('searchresults', '<div if="{!opts.results}"> <h1>Searching</h1> <p><i class="icon-refresh icon-spin"></i> Searching Resource Center</p> </div> <div if="{opts.results &amp;&amp; !opts.results.length}"> <h1>Not Found</h1> <p>Sorry no results for your search</p> </div> <div if="{opts.results &amp;&amp; opts.results.length}"> <h1>{opts.results.length} Search Results</h1> <table class="tabledefault"> <thead> <tr> <td>Publication Title</td> <td>Author</td> <td>Type</td> <td>Organisation</td> </tr> </thead> <tbody> <tr each="{paginated}"> <td><a href="{path}">{title}</a></td> <td class="prewrap">{multiline(author)}</td> <td>{type}</td> <td>{multiline(organisation)}</td> </tr> </tbody> </table> <rcpagination data="{opts.results}" perpage="100" updatepages="{handleUpdate}"></rcpagination> </div>', '.prewrap{ white-space:pre; }', '', function(opts) {
this.handleUpdate = (function(_this) {
  return function(pages) {
    _this.paginated = pages;
    return _this.update();
  };
})(this);

this.multiline = function(val) {
  return val.split(';').join('\n');
};
});
    
  