<script>// based on @sveltejs/svelte-virtual-list
import { onMount, afterUpdate, tick } from 'svelte'; // props

export let items;
export let height = '100%';
export let itemHeight = undefined; // read-only, but visible to consumers via bind:start

export let start = 0;
export let end = 0; // local state

let height_map = [];
let rows;
let viewport;
let contents;
let viewport_height = 0;
let visible;
let hasMounted;
let top = 0;
let bottom = 0;
let average_height;

$: visible = items.slice(start, end).map((data, i) => {
  return {
    index: i + start,
    data
  };
}); // whenever `items` changes, invalidate the current heightmap


$: if (hasMounted) refresh(items, viewport_height, itemHeight);

async function refresh(items, viewport_height, itemHeight) {
  const {
    scrollTop
  } = viewport;
  await tick(); // wait until the DOM is up to date

  viewport_height = viewport.offsetHeight || 200;
  let content_height = top - scrollTop;
  let i = start;

  while (content_height < viewport_height && i < items.length) {
    let row = rows[i - start];

    if (!row) {
      end = i + 1;
      await tick(); // render the newly visible row

      row = rows[i - start];
    }

    const row_height = height_map[i] = itemHeight || row && row.offsetHeight || 30;
    content_height += row_height;
    i += 1;
  }

  end = i;
  const remaining = items.length - end;
  average_height = (top + content_height) / end;
  bottom = remaining * average_height;
  height_map.length = items.length; // if no rows are showing due to data change, scroll to top

  if (start > items.length) {
    viewport.scrollTop = 0;
    handle_scroll();
  }
}

async function handle_scroll() {
  const {
    scrollTop
  } = viewport;
  const old_start = start;

  for (let v = 0; v < rows.length; v += 1) {
    height_map[start + v] = itemHeight || rows[v].offsetHeight;
  }

  let i = 0;
  let y = 0;

  while (i < items.length) {
    const row_height = height_map[i] || average_height;

    if (y + row_height > scrollTop) {
      start = i;
      top = y;
      break;
    }

    y += row_height;
    i += 1;
  }

  while (i < items.length) {
    y += height_map[i] || average_height;
    i += 1;
    if (y > scrollTop + viewport.offsetHeight) break;
  }

  end = i;
  const remaining = items.length - end;
  average_height = y / end;

  while (i < items.length) height_map[i++] = average_height;

  bottom = remaining * average_height; // prevent jumping if we scrolled up into unknown territory

  if (start < old_start) {
    await tick();
    let expected_height = 0;
    let actual_height = 0;

    for (let i = start; i < old_start; i += 1) {
      if (rows[i - start]) {
        expected_height += height_map[i];
        actual_height += itemHeight || rows[i - start].offsetHeight;
      }
    }

    const d = actual_height - expected_height;
    viewport.scrollTop = scrollTop + d;
  } // TODO if we overestimated the space these
  // rows would occupy we may need to add some
  // more. maybe we can just call handle_scroll again?

}

onMount(() => {
  rows = contents.getElementsByTagName('virtual-row');
  hasMounted = true;
  refresh(items, viewport_height, itemHeight);
});</script>

<style>
	.virtual-viewport {
		position: relative;
		overflow-y: auto;
		-webkit-overflow-scrolling:touch;
		display: block;
		min-height:20px;
	}

	.virtual-contents, .virtual-row {
		display: block;
	}
	/* removed so we could show re-order bar better */
	/* virtual-row {
		overflow: hidden;
	} */
</style>

<div class="virtual-viewport"
	bind:this={viewport}
	bind:offsetHeight={viewport_height} 
	on:scroll={handle_scroll}
	style="height: {height};position:absolute;top:0;left:0;right:0;bottom:0;"
>
	<div class="virtual-contents"
		bind:this={contents}
		style="padding-top: {top}px; padding-bottom: {bottom}px;"
	>
		{#each visible as row (row.index)}
			<div class="virtual-row">
				<slot item={row.data}>Missing template</slot>
			</div>
		{/each}
	</div>
</div>
