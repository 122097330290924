<script>import { Grid, api, Route, Modal, navigate, Search } from "duo-kit";
import ReportingSingle from "./reporting-single.svelte";
import { trackerusers } from "./store";
let selected = [];
let filtered = [];
trackerusers.load();

async function showUser() {
  navigate("/reporting/user/" + selected[0].id);
}</script>

<div class="row">
  <div class="searchbox">
    <Search placeholder="Search Users" bind:data={$trackerusers} bind:filtered={filtered} />
  </div>
  <div style="height:400px">
    <Grid 
      rowheight={30} data={filtered} 
      bind:selected
      on:click={showUser}
      columns={[
        {label:"First Name",field:"first_name",width:5},
        {label:"Surname",field:"surname",width:5},
        {label:"Email",field:"email",width:5},
        {label:"Records",field:"total",width:1},
      ]}
    >
      <div slot="footer" let:data>
        {filtered.length!=$trackerusers.length ? filtered.length+" of " : ""}{$trackerusers.length} Users
      </div>
    </Grid>
  </div>
</div>

<Route path=":user_id" let:params>
  <Modal heading={selected.length ? `${selected[0].first_name} ${selected[0].surname}` : 'User Report'} open on:closed={()=> navigate("/reporting/user") } style="width:90%">
    <ReportingSingle userid={params.user_id} editable={true} />
  </Modal>
</Route>


<style>
  .row{
    position:relative;
  }
  .searchbox{
    position: absolute;
    top:-45px;
    right:-5px;
  }
</style>