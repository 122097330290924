<script>import { Formrow, Button, api, IconSpinner, toasterStore, Alert } from "duo-kit";
let user = {},
    saving = false,
    complete = false;

async function save() {
  saving = true;

  try {
    await api.save({
      url: `/duocms/api/student/upgrade`,
      data: user
    });
    complete = true;
    user = {
      email: "",
      company: "",
      postcode: ""
    };
  } catch (err) {}

  saving = false;
}</script>

<form on:submit|preventDefault={save} style="--s-labelwidth:220px; display: flow-root;">
  <Formrow label="Your New Email Address" required name="email" type="email" bind:value={user.email} />
  <Formrow label="Your Company Name" name="company" type="text" bind:value={user.company} />
  <Formrow label="Your Company Post/Zipcode" name="postcode" autocomplete="postal-code" type="text" bind:value={user.postcode} />
  <div class="pull-right">
    <Button mode="primary" disabled={saving} type="submit">
      {#if saving} <IconSpinner /> {/if}
      Submit
    </Button>
  </div>
</form>
{#if complete}
  <Alert mode="success">
    <h3>Check your email</h3>
    <p>To update your account, we first need to validate the provided email.</p>
    <p>Please check your email for a message from us, then click the provided link to complete the upgrade.</p>
  </Alert>

{/if}
