
    var riot = require('riot')
    require('riot-kit/lib/popover');require('riot-kit/lib/datepicker');
riot.tag2('datepopover', '<yield></yield> <popover title="{opts.title}" placement="{opts.placement}" parent="{opts.parent}"> <datepicker date="{parent.opts.date}" onupdate="{parent.handleUpdate}"></datepicker> </popover>', '', '', function(opts) {
this.on('mount', function() {
  return this.root.addEventListener('click', this.handleClick);
});

this.on('unmount', function() {
  return this.root.removeEventListener('click', this.handleClick);
});

this.handleClick = (function(_this) {
  return function(e) {
    console.log('clicked');
    _this.tags.popover.clicked(e);
    return _this.tags.popover.tags.datepicker.trigger('mount');
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(date, changetypes) {
    if (opts.onupdate != null) {
      opts.onupdate(date);
    }
    if (changetypes.indexOf("month") === -1 && changetypes.indexOf("year") === -1) {
      return setTimeout(function() {
        if (_this.tags.popover != null) {
          return _this.tags.popover.hide();
        }
      });
    }
  };
})(this);
});
    
  