<script>import { Button, Modal, Formrow, api, toasterStore } from "duo-kit";
import { navigate } from "duo-kit";
import { createEventDispatcher } from "svelte";
const dispatch = createEventDispatcher();
export let teamid = "";
let open = true;
let saving = false;
let invites = "";

async function sendInvites() {
  let emails = invites.split("\n").map(email => email.trim()).filter(email => email); // remove white space and blank lines

  let invalid = false;
  emails.forEach(email => {
    if (!email.match(/@/)) {
      invalid = true;
    }
  });

  if (invalid) {
    toasterStore.add({
      title: "Sorry",
      type: "danger",
      message: "Please check your emails are valid before sending"
    });
    return;
  }

  saving = true;
  await api.save({
    url: "/duocms/api/tracker/sendinvites",
    data: {
      team_id: teamid,
      emails
    }
  });
  saving = false;
  toasterStore.add({
    title: "Invites Sent",
    type: "success",
    message: "Invites have been sent to the provided emails"
  });
  open = false;
  dispatch("saved");
}</script>
<Modal bind:open heading="Invite Team Members" on:closed={()=> navigate("/pseteams/")} style="width:90%;max-width:800px;">
  <div class="alert alert-info">
    <p>Enter the email addresses of users you would like to join your team, <strong>one per line.</strong></p>
    <p>Those with existing Nafems accounts will be sent an email asking them to confirm joining your team. Users without Nafems accounts will be sent an email asking them to create an account before joining.</p>
  </div>

  <Formrow type="textarea" bind:value={invites} rows={8} placeholder="eg. bob@website.com"/>

  <div slot="toolbar">
    <div class="pull-right">
      <Button mode="default" on:click={()=> open = null }>Close</Button>
      <Button mode="primary" on:click={sendInvites} disabled={saving}>{#if saving}<span class="fa fa-refresh fa-spin" />  Sending... {:else}Send Invites{/if}</Button>
    </div>
  </div>
</Modal>