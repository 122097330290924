
    var riot = require('riot')
    
riot.tag2('searchform', '<yield></yield> <p if="{showProgrss}"><i class="icon-refresh icon-spin"></i>Searching</p>', '', '', function(opts) {
var isEqual, prevdata;

prevdata = {
  q: "",
  categories: "",
  author: "",
  resource_type: "",
  audience: "",
  language: "",
  organisation: "",
  accesstype: ""
};

this.keypress = function(e) {
  console.log('keypress');
  if (e.keyCode === 13) {
    this.search();
  }
  return true;
};

this.on('mount', (function(_this) {
  return function() {
    var key, search, val;
    if (window.localStorage.search) {
      search = JSON.parse(window.localStorage.search);
      for (key in search) {
        val = search[key];
        if (key !== "categories" && (_this[key] != null)) {
          _this[key].value = val;
        }
      }
      _this.update();
    }
    return _this.search();
  };
})(this));

isEqual = function(obj, obj2) {
  var k, v;
  for (k in obj) {
    v = obj[k];
    if ("" + obj2[k] !== "" + v) {
      console.log(k, 'ob2', "" + obj2[k], 'ob1', "" + v);
      return false;
    }
  }
  return true;
};

this.search = function() {
  var data;
  this.showProgrss = true;
  data = {
    q: this.q.value,
    categories: this.categories.value,
    author: this.author.value,
    resource_type: this.resource_type.value,
    audience: this.audience.value,
    language: this.language.value,
    organisation: this.organisation.value,
    accesstype: this.accesstype.value
  };
  if (isEqual(data, prevdata)) {
    return;
  }
  prevdata = data;
  if (window.localStorage) {
    localStorage['search'] = JSON.stringify(data);
  }
  return console.log('searching');
};
});
    
  