<script>export let time = ""; // outputs time in local format - eg 14:35 in uk 2:35 pm in us

function convert() {
  let now = new Date();

  if (time) {
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    now.setHours(hours);
    now.setMinutes(minutes, 0, 0);
  }

  return now.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit'
  });
}

$: formattedTime = convert(time);</script>
{formattedTime}