
    var riot = require('riot')
    
riot.tag2('categories-branch', '<li each="{branches}" onclick="{parent.toggle.bind(null,id)}" class="{parent.getClass(id)}"> <div show="{!parent.opts.edit}"> <input type="checkbox" __checked="{getChecked(id)}" onclick="{setChecked}"><span data-catid="{id}">{id} {name}</span> </div> <div show="{parent.opts.edit==true}" ondragover="{parent.ondragover}" id="{id}" ondrop="{parent.ondrop}" draggable="true" ondragstart="{parent.ondragstart}" class="category"> <div class="input-group input-group-sm"> <input type="text" value="{name}" onblur="{updateName}" class="form-control"> <div class="input-group-btn"> <button ondragstart="{parent.ondragstart}" name="handle" class="btn btn-default"><span class="fa fa-reorder"></span></button> <button onclick="{remove}" class="btn btn-danger"> <span class="fa fa-times"></span></button> </div> </div> </div> <ul if="{parent.open[id]}"> <categories-branch parentid="{id}" treedata="{parent.opts.treedata}" edit="{parent.opts.edit}" ticks="{parent.opts.ticks}" addfield="{parent.opts.addfield}" tickupdate="{parent.opts.tickupdate}" removefield="{parent.opts.removefield}"></categories-branch> </ul> </li> <li show="{opts.edit==true}"> <button onclick="{addCategory}" class="btn btn-sm btn-default">Add Category</button> </li>', '', '', function(opts) {
this.open = [];

this.branches = [];

this.first_open = false;

this.prev_parent_id = null;

this.on('update', function() {
  this.branches = this.getBranch(opts.parentid);
  if (!this.first_open && this.branches.length && opts.ticks) {
    this.branches.forEach((function(_this) {
      return function(branch) {
        return _this.open[branch.id] = _this.getChecked(branch.id);
      };
    })(this));
    return this.first_open = true;
  } else if (this.open.length === 0) {
    return this.branches.forEach((function(_this) {
      return function(branch) {
        return _this.open[branch.id] = false;
      };
    })(this));
  }
});

this.toggle = (function(_this) {
  return function(id, e) {
    if (e.target.tagName === "INPUT") {
      return;
    }
    e.stopPropagation();
    _this.open[id] = !_this.open[id];
    return _this.update();
  };
})(this);

this.getClass = function(id) {
  if ((!opts.edit && this.getBranch(id).length < 1) || !id) {
    return '';
  }
  if (this.open[id]) {
    return 'open';
  }
  return 'closed';
};

this.addCategory = function(e) {
  var newrow;
  e.stopPropagation();
  newrow = {
    name: '',
    id: false,
    parent_id: opts.parentid
  };
  return opts.addfield(newrow);
};

this.remove = function(e) {
  e.stopPropagation();
  opts.removefield(e.item);
  return this.update();
};

this.updateName = function(e) {
  return e.item.name = e.target.value;
};

this.getBranch = (function(_this) {
  return function(id) {
    if (!opts.treedata || id === false) {
      return [];
    }
    return opts.treedata.filter(function(row) {
      return "" + row.parent_id === "" + id;
    });
  };
})(this);

this.setChecked = function(e) {
  return opts.tickupdate(e.item);
};

this.getChecked = (function(_this) {
  return function(id) {
    return opts.ticks.indexOf(+id) > -1;
  };
})(this);

this.ondragstart = (function(_this) {
  return function(event) {
    event.preventUpdate = true;
    event.dataTransfer.setData("text", event.target.id);
    return true;
  };
})(this);

this.ondragover = (function(_this) {
  return function(event) {
    event.preventUpdate = true;
    event.dataTransfer.dropEffect = "move";
    return event.preventDefault();
  };
})(this);

this.ondrop = (function(_this) {
  return function(event) {
    var i, id, idx, len, ref, results, row;
    event.preventDefault();
    event.preventUpdate = true;
    id = event.dataTransfer.getData("text");
    ref = opts.treedata;
    results = [];
    for (idx = i = 0, len = ref.length; i < len; idx = ++i) {
      row = ref[idx];
      if (+row.id === +id) {
        opts.treedata[idx].parent_id = opts.parentid;
        riot.update();
        break;
      } else {
        results.push(void 0);
      }
    }
    return results;
  };
})(this);
});
    
  