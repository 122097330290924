<script>import { Button, navigate, Route, Grid } from "duo-kit";
import ReportingMe from "./reporting-single.svelte";
import ReportingUsers from "./reporting-users.svelte";
import ReportingCompetence from "./reporting-competence.svelte";
import ReportingTeam from "./reporting-team.svelte";
export let route = "";
let selected = [];
import { session } from "./store.js";
session.load();

function openReport() {
  window.open("/duocms/api/tracker/reports/users/me/pdf");
}</script>

<h2>Reporting</h2>

<Button mode={route == "/reporting/" ? "primary" : "default"} on:click={()=> navigate("/reporting/")}>My Competence Records</Button>
<Button on:click={openReport}>Download My Report</Button>
<Button mode={route == "/reporting/team" ? "primary" : "default"} on:click={()=> navigate("/reporting/team")} >By Team</Button>

{#if $session && $session.permissions && $session.permissions.includes("users_all")}
  <Button mode={route == "/reporting/user" ? "primary" : "default"} on:click={()=> navigate("/reporting/user")}>By User *</Button>
{/if}
{#if $session && $session.permissions && $session.permissions.includes("users_all")}
  <Button mode={route == "/reporting/competence" ? "primary" : "default"} on:click={()=> navigate("/reporting/competence")}>By Competence *</Button>
{/if}


<Route path="" exact><ReportingMe showdelete={true} /></Route>

<Route path="user" ><ReportingUsers /></Route>

<Route path="team" ><ReportingTeam /></Route>

<Route path="competence" ><ReportingCompetence /></Route>

{#if $session && $session.permissions && $session.permissions.includes("users_all")}
  <p><small>* Only available to Nafems Admin</small></p>
{/if}