
    var riot = require('riot')
    
riot.tag2('categories', '<input type="text" if="{loading}" class="form-control"> <category each="{categoryParents}" categories="{parent.data}" level="{level}" parent="{parent_id}" onselect="{parent.categoryChosen}" value="{selected}"></category> <input type="hidden" name="{opts.name}" value="{categories_value}">', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on('mount', (function(_this) {
  return function() {
    if (opts.value) {
      _this.categories_value = opts.value;
    }
    return xhr("get", '/duocms/api/resourcecenter/categories', null, function(data) {
      var ref;
      _this.loading = false;
      _this.data = data != null ? (ref = data.body) != null ? ref.data : void 0 : void 0;
      _this.categoryParents = [
        {
          parent_id: 0,
          level: 0
        }
      ];
      if (_this.categories_value) {
        _this.categories_value.split(",").forEach(function(row, idx) {
          _this.categoryParents[idx].selected = +row;
          return _this.categoryParents.push({
            parent_id: +row,
            level: idx + 1
          });
        });
      }
      console.log("categoryParents", _this.categoryParents);
      return _this.update();
    });
  };
})(this));

this.categoryParents = [];

this.categoryChosen = (function(_this) {
  return function(level, item) {
    var ref, ref1;
    _this.categoryParents.length = level;
    if (item !== "") {
      _this.categoryParents[level] = {
        parent_id: item,
        level: level
      };
    }
    if ((ref = _this.parent) != null ? ref.catagories : void 0) {
      _this.parent.catagories.value = _this.categoryParents[_this.categoryParents.length - 1].parent_id;
      _this.parent.search();
    }
    console.log("chosen", _this.categoryParents);
    _this.categories_value = _this.categoryParents.filter(function(row) {
      return row.parent_id;
    }).map(function(row) {
      return row.parent_id;
    }).join(",");
    _this.update();
    return (ref1 = _this.root.closest("form")) != null ? ref1.submit() : void 0;
  };
})(this);

this.root.closest = this.root.closest != null ? this.root.closest : function(selector) {
  var el;
  if (el.closest != null) {
    return el.closest(selector);
  }
  while (el) {
    if (el.matches(selector)) {
      return el;
    }
    el = el.parentElement;
  }
};
});
    
  