<script>export let on = true;
import IconWrap from "./iconwrap.svelte";</script>
<IconWrap>
<svg height="100%" version="1.1" style={(on ? "opacity:1" : "opacity:0.5")} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
 <g fill="currentColor">
  <path d="m43.699 15.602l-23.301 19.102-13 1.1992c-2.6992 0.30078-4.8008 2.5-4.8008 5.3008v17.699c0 2.6992 2.1016 5 4.8008 5.3008l13 1.1992 23.301 19.102c1.8984 1.6016 4.8008 0.19922 4.8008-2.3008v-64.301c-0.10156-2.5039-2.8984-3.9023-4.8008-2.3008z"/>
  {#if on}
    <path d="m66.199 39c-1.3008-2.1992-4.3008-2.6992-6.3008-1l-0.30078 0.19922c-1.3984 1.1992-1.8008 3.3008-0.89844 4.8984 1.1016 2 1.8008 4.3008 1.8008 6.8008s-0.69922 4.8008-1.8008 6.8008c-0.89844 1.6016-0.5 3.6992 0.89844 4.8984l0.30078 0.19922c1.8984 1.6992 5 1.3008 6.3008-1 1.8008-3.1992 2.8984-7 2.8984-11 0.003906-3.7969-1.0977-7.4961-2.8984-10.797z"/>
    <path d="m76.699 29.102c-1.3984-2-4.3008-2.3008-6.1016-0.69922l-0.19922 0.19922c-1.5 1.3984-1.8008 3.6992-0.60156 5.3984 3.1992 4.5 5 10.102 5 16 0 6-1.8984 11.5-5 16-1.1992 1.6992-0.89844 4 0.60156 5.3984l0.19922 0.19922c1.8008 1.6016 4.6992 1.3008 6.1016-0.69922 4.1992-5.8984 6.6016-13.102 6.6016-20.898 0-7.8008-2.4023-15-6.6016-20.898z"/>
    <path d="m87.199 19.398c-1.5-1.8984-4.1992-2.1992-6-0.60156l-0.30078 0.19922c-1.6016 1.3984-1.8008 3.8008-0.5 5.5 5.3984 7.1016 8.5 15.898 8.5 25.398s-3.1992 18.398-8.5 25.398c-1.3008 1.6992-1.1016 4.1016 0.5 5.5l0.30078 0.19922c1.8008 1.6016 4.5 1.3008 6-0.60156 6.5-8.5 10.301-19.102 10.301-30.602 0-11.289-3.8008-21.891-10.301-30.391z"/>
  {:else}
    <path d="m85.398 50l11.301-11.602c1.6992-1.8008 1.6992-4.6016-0.10156-6.3984-1.8008-1.6992-4.6016-1.6992-6.3984 0.10156l-11.098 11.5-11.102-11.5c-1.6992-1.8008-4.6016-1.8008-6.3984-0.10156-1.8008 1.6992-1.8008 4.6016-0.10156 6.3984l11.301 11.602-11.301 11.602c-1.6992 1.8008-1.6992 4.6016 0.10156 6.3984 0.80078 0.80078 2 1.3008 3.1016 1.3008 1.1992 0 2.3984-0.5 3.1992-1.3984l11.102-11.5 11.102 11.5c0.89844 0.89844 2 1.3984 3.1992 1.3984 1.1992 0 2.3008-0.5 3.1016-1.3008 1.8008-1.6992 1.8008-4.6016 0.10156-6.3984z"/>
  {/if}
 </g>
</svg>
</IconWrap>