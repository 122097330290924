<script>// show popover slot on click
import { tick } from "svelte";
import { fly } from 'svelte/transition';
import Portal from "./portal.svelte";
import Button from "./button.svelte";
export let placement = "top";
export let title = "";
export let open = false;
export let mode = "";
export let size = "";
export let disabled = null;
export let target = null;
export let ariaLabel = null;
let className = "";
export { className as class };
let button, popover;
let top = 0;
let left = 0;
let scrollY;
let startY;
let scrollX;
let startX;
let trany = 0;
let tranx = 0;

const pop = async e => {
  e.stopPropagation();
  open = !open;

  if (open) {
    startY = scrollY;
    startX = scrollX;
    startTransition();
    await tick(); // wait for update

    let buttonPos = button.getBoundingClientRect();
    let popoverPos = popover.getBoundingClientRect(); // position popover in correct place

    placePop({
      btn: buttonPos,
      pop: popoverPos
    });
  }
};

const startTransition = () => {
  trany = 0;
  tranx = 0;

  switch (placement) {
    case "top":
      return trany = 20;

    case "right":
      return tranx = -20;

    case "bottom":
      return trany = -20;

    case "left":
      return tranx = 20;
  }
};

const placePop = ({
  btn,
  pop
}) => {
  left = placement == "top" || placement == "bottom" ? btn.left - pop.width / 2 + btn.width / 2 : null;
  top = placement == "right" || placement == "left" ? btn.top - pop.height / 2 + btn.height / 2 : null;

  if (placement == "top") {
    top = btn.top - pop.height - 5;
  }

  if (placement == "right") {
    left = btn.left + btn.width;
  }

  if (placement == "bottom") {
    top = btn.top + btn.height + 5;
  }

  if (placement == "left") {
    left = btn.left - pop.width;
  } // don't let popover go outside window


  if (left < 0) left = 0;
  if (pop.width + left > window.innerWidth) left = window.innerWidth - pop.width;
  if (top < 0) top = 0;
  if (pop.height + top > window.innerHeight) top = window.innerHeight - pop.height;
};</script>

<svelte:window bind:scrollY={scrollY} bind:scrollX={scrollX} />

{#if mode}
  <Button on:click={pop} bind:elem={button} mode={mode} size={size} {disabled} aria-label={ariaLabel} >
    <slot ></slot>
  </Button>
{:else}
  <button on:click={pop} bind:this={button} class={className} {disabled} aria-label={ariaLabel} >
    <slot ></slot>
  </button>
{/if}
{#if open}
  <Portal {target} >
    <div class="pop-backdrop" on:click={(e)=> open=false} ></div>
    <div class="spopover {placement}" transition:fly="{{ y: trany,x:tranx,duration: 200 }}" style="display:block;position:fixed;top:{top+startY-scrollY}px;left:{left+startX-scrollX}px" bind:this={popover}>
      {#if title}
        <h3 class="spopover-title">{title}</h3>
      {/if}
      <div class="spopover-content">
        <slot name="content"></slot>
      </div>
    </div>
  </Portal>
{/if}


<style>
   * {
     box-sizing: border-box
   }
  .spopover-title{
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
  }
  .spopover{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060; 
    padding: 1px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 0;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    white-space: normal;
  }
  .spopover-content{
    padding: 9px 14px;
  }
  .pop-backdrop{
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    background:rgba(0,0,0,0.01)
  }
</style>