<script>import Button from "./button.svelte";
export let data;
export let filtered;
export let page = 0;
export let perpage = 10;
export let max = 10;
export let nextprev;
export let size = "";
let buttons = [];
let startpage = 0;
export let btntotal;

function updateButtons() {
  if (!data.length) return [];
  buttons = [];
  let total = data.length / perpage;

  for (let i = startpage; i < total; i++) {
    if (i >= max + startpage) break;
    buttons.push(i + 1);
  }

  setPage();
  return buttons;
}

function setPage(p) {
  if (p != null) page = p;
  let start = page * perpage;
  if (start > data.length) return setPage(0); // go back to first page if there aren't enough results in data

  let end = start + +perpage;
  btntotal = Math.ceil(data.length / perpage);
  filtered = data.slice(start, end);

  if (page >= Math.ceil(max / 2) && max <= btntotal) {
    startpage = page - Math.floor(max / 2) < btntotal - max ? page - Math.floor(max / 2) : btntotal - max;
  } else {
    startpage = 0;
  }

  console.log({
    page,
    startpage,
    perpage,
    start,
    end
  });
}

$: buttons = updateButtons(data, page, startpage, perpage);</script>

{#if buttons && buttons.length > 1}
  <div class="spagination">
    {#if nextprev}<Button size={size} mode="default" on:click={()=> setPage(page-1)} disabled={page==0}>«</Button>{/if}
    {#each buttons as button}
      <Button size={size} mode={button==page+1 ? "primary" : "default"} on:click={()=> setPage(button-1)}>{button}</Button>
    {/each}
    {#if nextprev}<Button size={size} mode="default" on:click={()=> setPage(page+1)} disabled={page+1 >= btntotal}>»</Button>{/if}
  </div>
{/if}

<style>
  .spagination{
    overflow:hidden;/* fix float */
  }
  .spagination :global(.sbtn){
    margin:0;
    border-radius: var(--s-border-radius,0px);
    border-right-width:0;
    display:block;
    float:left;
  }

  .spagination :global(.sbtn:first-child){
    border-radius: var(--s-border-radius,4px) 0 0 var(--s-border-radius,4px);
  }
  .spagination :global(.sbtn:last-child){
    border-radius: 0 var(--s-border-radius,4px) var(--s-border-radius,4px) 0;
    border-right-width:1px;
  }

</style>
