<script>import showdown from 'showdown';
import xss from "xss";
import { afterUpdate, tick } from "svelte";
import Button from "./button.svelte";
import Filedrop from "./filedrop.svelte";
import Upload from "../icons/upload.svelte";
import { autoheight } from "./actions.js";
export let autofocus = null;
export let value = "";
export let readonly = null;
export let files = [];
export let placeholder = "";
export let id = "";
let mode = "write";
let field = null;
let emojis = [];
let droppedFiles = [];
let fileinputid = "file" + Math.round(Math.random() * 10000);
let textwidth;
let textheight;
let selectionStart;
let cursor;
let emojilist;
let emojiselected;
let emojivisible = false;
const converter = new showdown.Converter({
  underline: true,
  strikethrough: true,
  simpleLineBreaks: true,
  openLinksInNewWindow: true,
  emoji: true,
  simplifiedAutoLink: true,
  tables: true,
  tasklists: true,
  parseImgDimensions: true
});

const makeHTML = value => {
  let html = converter.makeHtml(value);
  html = xss(html, {
    onTagAttr: (tag, name, value, isWhiteAttr) => {
      if (tag === "img" && name === "src") {
        return 'src="' + value + '"';
      }
    }
  });
  droppedFiles.forEach(file => {
    html = html.replace('src="' + file.name + '"', 'src="' + file.base64 + '" style="max-width:100%"');
    html = html.replace('href="' + file.name + '"', 'href="' + file.base64 + '" download="' + file.name + '"');
  }); // convert youtube to embed

  html = html.replace(/<img +src="https?:\/\/(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^&"]+)[^>]+>/g, "<div class='video'><iframe src='//www.youtube.com/embed/$3?rel=0' frameborder='0' allowfullscreen></iframe></div>");
  return html;
};

const handleDropFiles = e => {
  if (e.detail.files) {
    addFilesToInput(e.detail.files);
  }
};

const chooseFile = e => {
  var event = document.createEvent("UIEvents");
  event.initUIEvent("click", true, true);
  document.getElementById(fileinputid).dispatchEvent(event);
};

function handlePickedFiles(e) {
  addFilesToInput(this.files);
}

const addFilesToInput = inputfiles => {
  try {
    let s = field.selectionStart;

    for (let file of Array.from(inputfiles)) {
      let name = file.name.replace(/ /g, "_");
      let placeholder = "[" + name + "](" + name + ")";

      if (file.type.match(/^image\//)) {
        placeholder = "![" + name + "](" + name + ")";
      }

      let shortplaceholder = "](" + name + ")"; // alows user to change alt, but still upload

      let reader = new FileReader();

      reader.onloadend = () => {
        droppedFiles.push({
          placeholder: shortplaceholder,
          file,
          name,
          base64: reader.result
        });
        files = droppedFiles.filter(file => value.indexOf(file.placeholder) > -1); // set files to those that are used
      };

      reader.readAsDataURL(file); // insert placeholder

      value = value.substring(0, s) + placeholder + value.substring(s);
    }
  } catch (err) {
    console.log("file reader error", err);
  }
};

afterUpdate(() => {
  files = droppedFiles.filter(file => value.indexOf(file.placeholder) > -1); // set files to those that are used
});

const setTab = val => {
  mode = val;

  if (mode == "preview") {
    html = makeHTML(value);
  }
};

const wrapSelection = txt => {
  let s = field.selectionStart;
  let e = field.selectionEnd;
  value = value.substring(0, s) + txt + value.substring(s, e) + txt + value.substring(e);
  setTimeout(() => {
    field.selectionStart = s + txt.length;
    field.selectionEnd = e + txt.length;
  });
};

const bold = e => {
  e.preventDefault();
  wrapSelection("**");
};

const italic = e => {
  e.preventDefault();
  wrapSelection("*");
};

const underline = e => {
  e.preventDefault();
  wrapSelection("__");
};

const typing = e => {
  selectionStart = e.target.selectionStart;
  emoji(e);
}; // handle emoji keys


const emojiMenuKeys = e => {
  if (!emojivisible) return;

  if (e.key == "ArrowDown" && emojiselected < emojis.length - 1) {
    emojiselected += 1;
    e.preventDefault();
  }

  if (e.key == "ArrowUp" && emojiselected > 0) {
    emojiselected -= 1;
    e.preventDefault();
  }

  if (e.key == "Enter") {
    setEmoji();
    e.preventDefault();
  }
}; // insert select emoji at selection, focus cursor just after


const setEmoji = () => {
  let emotxtmatch = value.substr(0, selectionStart).match(/:([^:]+)$/);
  let start = selectionStart - emotxtmatch[0].length;
  let emojiend = value.substr(selectionStart).match(/^[^\s]+:/); // fix if we're inside on an emoji

  let end = selectionStart + emotxtmatch.length + (emojiend ? emojiend[0].length : 0);
  value = value.substr(0, start) + ":" + emojis[emojiselected] + ": " + value.substr(end);
  emojivisible = false;
  field.focus(); // field.setSelectionRange(end, end)
};

const emoji = e => {
  let emotxtmatch = value.substr(0, selectionStart).match(/:([^:]+)$/);
  let emotxt = emotxtmatch && emotxtmatch[1];
  let found = Object.keys(showdown.helper.emojis).filter(k => k.includes(emotxt) && !showdown.helper.emojis[k].startsWith("<")); // sort them by the ones starting with the text first

  found = found.sort((a, b) => a.startsWith(emotxt) && b.startsWith(emotxt) ? 0 : b.startsWith(emotxt) ? 1 : -1);
  emojis = found.slice(0, 5); // if found show emoji menu

  if (emotxt && emotxt.length && emojis.length && !emojivisible) {
    // position on first found letter
    emojivisible = true;
    emojiselected = 0;
    positionEmojiList();
  } else if (!emotxt || !emotxt.length || !emojis.length) {
    emojivisible = false;
  }
};

const positionEmojiList = async () => {
  await tick();
  emojilist.style.top = cursor.offsetTop + cursor.offsetHeight + "px";
  emojilist.style.left = cursor.offsetLeft - 20 + "px";
};

$: value = value ? value.replace(/\\n/g, "\n") : "";

$: html = makeHTML(value);</script>

{#if readonly}
  <div class="preview">
    {@html html}
  </div>
{:else}
  <div class="markdowninput" tabindex="0">
    <div class="stabs">
      <button class={mode == "write" ? "active" : ""} on:click={()=> setTab("write")}>Write</button>
      <button class={mode == "preview" ? "active" : ""} on:click={()=> setTab("preview")}>Preview</button>

      <div class="stoolbar">
        <Button size="sm" title="bold" on:mousedown={bold}><strong>B</strong></Button>
        <Button size="sm" title="italic" on:mousedown={italic}><em>I</em></Button>
        <Button size="sm" title="underline" on:mousedown={underline}><u>U</u></Button>
        <label class="uploadbutton" for={fileinputid}><Upload /></label>
        <input class="uploadinput" type="file" name={fileinputid} id={fileinputid} on:change={handlePickedFiles} />
      </div>
    </div>
    {#if mode=="write"}
      <div class="inputarea">
        <Filedrop let:files on:updated={handleDropFiles}>
          <div style="position:relative">
            <textarea id={id} use:autoheight placeholder={placeholder || "write your comment or drag your files here"} on:keyup={typing} on:keydown={emojiMenuKeys} bind:value={value} {autofocus} bind:clientWidth={textwidth} bind:clientHeight={textheight} bind:this={field} ></textarea>
            {#if selectionStart}
              <div class="cusortrack" style="width:{textwidth}px;height:{textheight}px;">{value.substr(0,selectionStart)}<span class="cursor" bind:this={cursor} />{value.substr(selectionStart)}</div>
            {/if}
            {#if emojivisible}
              <div bind:this={emojilist} class="emojilist">
                {#each emojis as emoji,idx}
                  <div class={emojiselected == idx ? "active" : ""} on:click={(e)=> setEmoji()} on:mouseover={(e)=> emojiselected=idx }>{showdown.helper.emojis[emoji]} :{emoji}:</div>
                {/each}
              </div>
            {/if}
          </div>
        </Filedrop>
        
      </div>
    {/if}
    {#if mode=="preview"}
      <div class="preview">{@html html}</div>
    {/if}
  </div>
{/if}


<style>
  .uploadinput{
    position:fixed;
    top: -100px; /* hidden off screen */
  }
  .uploadbutton{
    border:1px solid #ccc;
    position: relative;
    box-sizing: border-box;
    background:#fff;
    text-decoration: none;
    color:#333;
    white-space: nowrap;
    display: inline-block;
    margin:var(--s-button-margins,4px);
    text-align: center;
    cursor: pointer;
    border-radius:var(--s-border-radius,4px);
    box-shadow:0 0 0px rgba(0,0,0,0.5),0 0px 0px rgba(0,0,0,0.1);
    transition:box-shadow 0.2s;
    vertical-align: middle;
    font-size:12px; 
    padding:0px 10px;
    height:26px;
    line-height:16px;
  }
  .uploadbutton:hover{
    box-shadow:0 0 2px rgba(0,0,0,0.1),0 0px 0px rgba(0,0,0,0.1);
    cursor:pointer;
  }
  .uploadbutton :global(.d-icon){ top:0.4em;} /* not sure why this is neccessary ?*/
  .markdowninput{
    width:100%;
    border:1px solid #ddd;
  }
  .stabs {
    text-align:left;
    background:#eee;
    padding:10px 10px 0 10px;
    border-bottom:1px solid #ccc;
    position:relative;
  }
  .stabs button{
    position: relative;
    box-sizing: border-box;
    padding:7px 12px;
    background:#fff;
    border:0px;
    font-size: 14px;
    text-decoration: none;
    color:black;
    white-space: nowrap;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    line-height:1.25;
    border-radius:var(--s-border-radius,4px) var(--s-border-radius,4px) 0 0;
    line-height:18px;
    border:1px solid #ccc;
    color:rgba(0,0,0,0.7);
    margin-bottom:-1px;
  }
  .stabs button.active{
    color:black;
    border:1px solid #ccc;
    border-bottom:1px solid white;
    
  }
  .stabs button:active,.stabs button:focus{
    outline:0px;
    color:black;
    border:2px solid #ccc;
    border-bottom:1px solid white;
  }
  .inputarea,.preview{
    padding:10px;
  }
  .inputarea textarea,.cusortrack{
    font-weight:normal;
    border:0px;
    font-size:14px;
    resize: none;
    width:100%;
  }
  .inputarea textarea:focus{
    outline:0px;
  }
  .stoolbar{
    position: absolute;
    top:10px;
    right:10px;
    --s-button-margins:0px;
  }
  .preview :global(img){
    max-width:100%;
  }
  .preview :global(.video){
    width:100%;
    padding-top:56.25%;
    position:relative;
  }
  .preview :global(.video iframe){
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    border:0;
  }
  .preview > :global(:first-child){
    margin-top:0;
  }
  .cusortrack{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    padding:2px;
    overflow-wrap:break-word;
    white-space:pre-wrap;
    z-index:2;
    line-height:normal;
    font:400 11px system-ui;
    font-size:14px;
    pointer-events: none;
    opacity:0.01;
  }
  .cusortrack .cursor{
    outline:1px solid black;
  }

  .emojilist{
    position:absolute;
    top:0;
    left:0;
    border:1px solid #eee;
    padding:1px;
    background:white;
    z-index:3;
    border-radius:2px;
    box-shadow:0 1px 3px rgba(0,0,0,0.3);
  }
  .emojilist > div{
    padding:5px 8px;
    cursor:pointer; 
  }
  .emojilist > .active{
    background:#eee;
  }

</style>