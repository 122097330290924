<script>export let heading = "";
export let style = "";
export let open = null;
export let hide = false;
export let target = null;
import Portal from "./portal.svelte";
import { afterUpdate, createEventDispatcher } from 'svelte';
import { scale, fade } from 'svelte/transition';
let diagName = "dialog" + Math.round(Math.random() * 10000);
const SLOTS = $$slots;
const dispatch = createEventDispatcher();

const afterClose = () => {
  dispatch('closed');
};</script>


{#if open}
  <Portal zindex={hide ? -1 : 1060} {target}>
    <div class={'backdrop'} transition:fade|global={{duration: 200 }}>
      <div class='smodal' transition:scale|global="{{start: 0,duration: 200 }}" on:outroend="{() => dispatch('closed')}" tabindex="-1" role="dialog" aria-labelledby={diagName+"Title"} aria-hidden="true" style={style} >
        <button class="close" on:click={(e)=> open = null}>&times;</button>
        {#if heading}
          <div class="heading"><h3 id={diagName+"Title"}>{heading}</h3></div>
        {/if}
        <slot></slot>
        {#if SLOTS && SLOTS.toolbar}
          <div class="modaltoolbar">
            <slot name="toolbar"></slot>
          </div>
        {/if}
      </div>
    </div>
  </Portal>
{/if}


<style>
  :host{
    display:block;
  }
  .smodal{
    display:block;
    position:static;
    background:white;
    z-index:1000;
    padding:15px;
    border-radius:var(--s-border-radius,4px);
    box-shadow:0 3px 10px rgba(0,0,0,0.5);
    transition:transform 0.5s;
    transform:scale3d(1,1,1);
    height: auto;
    max-width:100%;
    min-width:300px;
    align-self: flex-start;
  }

  .backdrop{
    position:fixed;
    background-color:rgba(0,0,0,0.4);
    padding:20px;
    top:0;
    left:0;
    right:0;
    bottom:0;
    transition:background-color 0.5s;
    z-index:999;
    display:flex;
    align-content:start;
    overflow:auto;
    align-items: start;
    justify-content: center;
  }

  .heading h3{
    margin:3px 0;
    font-size:21px;
    font-weight:normal;
  }
  .heading{
    border-radius:var(--s-border-radius,4px) var(--s-border-radius,4px) 0 0;
    padding:10px 15px 10px 15px;;
    margin:-15px;
    margin-bottom:10px;
    border-bottom:1px solid rgba(0,0,0,0.1);
  }
  .close{
    background:transparent;
    border:0px;
    font-size:24px;
    font-weight:bold;
    position:absolute;
    right:0px;
    top:0px;
    padding:7px 15px;
    cursor:pointer;
    opacity:0.5
  }
  .close:hover{
    opacity:1;
  }
  .modaltoolbar{
    border-top:1px solid #eee;
    padding:10px 10px 0px 10px;
    margin:20px -15px 0 -15px;
  }
  .modaltoolbar:blank{
    display:none;
  }
</style>