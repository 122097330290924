<script>import AddressSearch from "./address-search.svelte";
import { onMount } from "svelte";
let component;
let country;
let disabled = true;
onMount(() => {
  let checkout = document.querySelector("checkout");

  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        addAddressLookup();
      }
    }
  };

  const observer = new MutationObserver(callback);
  observer.observe(checkout, {
    attributes: false,
    childList: true,
    subtree: false
  });
});

function addAddressLookup() {
  let address = document.querySelector("checkout textarea[name='billing.address']");
  let addressSearch = document.querySelector("checkout .address-search");

  if (address && !addressSearch) {
    let div = document.createElement("div");
    div.setAttribute("class", "address-search");
    div.setAttribute("style", "margin:0 0 1rem 33.333%;");
    component = new AddressSearch({
      target: div
    });
    address.closest("formgroup").before(div);
    component.$on("userupdated", addressUpdated);
    component.$on("custom", () => {
      document.querySelector("checkout textarea[name='billing.address']").removeAttribute("disabled");
      document.querySelector("checkout input[name='billing.city']").removeAttribute("disabled");
      document.querySelector("checkout input[name='billing.postcode']").removeAttribute("disabled");
      document.querySelector("checkout select[name='billing.country']").removeAttribute("disabled");
    });
  } // get country from riot checkout and pass into svelte address search


  let riotCountry = document.querySelector("checkout")._tag.transaction.billing.country;

  if (riotCountry && riotCountry != country && component) {
    country = riotCountry;
    component.$set({
      user: {
        country: country
      }
    });
  }

  if (disabled) {
    document.querySelector("checkout textarea[name='billing.address']").setAttribute("disabled", "disabled");
    document.querySelector("checkout input[name='billing.city']").setAttribute("disabled", "disabled");
    document.querySelector("checkout input[name='billing.postcode']").setAttribute("disabled", "disabled");
    document.querySelector("checkout select[name='billing.country']").setAttribute("disabled", "disabled");
  }
} // updates riot address when chosen in address lookup


function addressUpdated(e) {
  let updated = e.detail;
  console.log({
    updated
  });
  document.querySelector("checkout")._tag.transaction.billing.address = updated.address;
  document.querySelector("checkout")._tag.transaction.billing.city = updated.city;
  document.querySelector("checkout")._tag.transaction.billing.postcode = updated.postcode;
  document.querySelector("checkout")._tag.transaction.billing.country = updated.country;

  document.querySelector("checkout")._tag.update();
}</script>