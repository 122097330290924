
    var riot = require('riot')
    
riot.tag2('mydownloads', '<div if="{!downloads}" class="alert alert-info">Getting Downloads List</div> <div if="{downloads.length == 0}" class="alert alert-info">You have no purchased downloads</div> <div if="{downloads.length}"> <table class="table table-striped"> <thead> <tr> <th>Title </th> <th>Purchase Date </th> <th>Expires</th> <th></th> </tr> </thead> <tbody> <tr each="{downloads}"> <td>{title}</td> <td> <dateformat date="{new Date(date)}" format="HH:mm dd/MM/yyyy"></dateformat> </td> <td> <dateformat if="{expires}" date="{new Date(expires)}" format="HH:mm dd/MM/yyyy"></dateformat><span hide="{expires}">Never</span> </td> <td> <dropdown if="{locklizard_id}" class="dropdown locklizardmenu"> <button onclick="{toggle}" class="btn btn-primary btn-block"> Open <span class="fa fa-caret-down"></span> </button> <ul class="dropdown-menu"> <li><a href="#" onclick="{parent.webviewer}">Open in Webviewer</a></li> <li><a href="#" onclick="{parent.download}">Download PDC</a></li> </ul> </dropdown> <button onclick="{download}" if="{!locklizard_id}" class="btn btn-primary btn-block">Open</button> </td> </tr> </tbody> </table> </div> <div if="{hasLocklizard}" class="alert alert-info"> <p>Some documents are secured with LockLizard and may require you to download and install the following licence file.</p><a href="/duocms/api/locklizard/licence" class="btn btn-info btn-sm">Download Licence</a> </div> <modal name="video-modal" title="Video" onclose="{true}" closetext="Close" bssize="lg"> <div class="embed-responsive embed-responsive-16by9"> <iframe riot-src="{\'https://player.vimeo.com/video/\'+parent.video_id+\'?byline=false&amp;color=#d00\'}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> </div> </modal>', '.locklizardmenu.dropdown.open .dropdown-menu { max-height: 100px; }', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.hasLocklizard = false;

this.on("mount", function() {
  this.downloads = null;
  return xhr("GET", "/duocms/api/resourcecenter/mydownloads", null, (function(_this) {
    return function(res) {
      _this.downloads = res.body.data || [];
      _this.downloads.forEach(function(d) {
        if (d.locklizard_id) {
          return _this.hasLocklizard = true;
        }
      });
      return _this.update();
    };
  })(this));
});

this.webviewer = (function(_this) {
  return function(e) {
    return window.open("/duocms/api/locklizard/mydownloads/?locklizard_id=" + e.item.locklizard_id + "&token=" + e.item.lltoken + "&v=" + new Date().getTime());
  };
})(this);

this.download = (function(_this) {
  return function(e) {
    var item, ref, ref1;
    item = e.item;
    if ((ref = item.document_url) != null ? ref.match(/vimeo\.com/) : void 0) {
      _this.video_id = (ref1 = item.document_url.match(/\d+/)) != null ? ref1[0] : void 0;
      return _this.tags['video-modal'].show();
    } else {
      return window.open("/duocms/api/resourcecenter/download?document_url=" + item.document_url + "&token=" + item.token);
    }
  };
})(this);
});
    
  