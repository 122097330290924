<script>import { Formrow, Button, api, toasterStore } from "duo-kit";
import { session } from "./store";
session.load();

async function save() {
  let user = { ...$session
  };
  user.id = "me"; // 

  await api.save({
    url: `/duocms/api/users`,
    data: user
  });
  toasterStore.add({
    title: "Saved",
    type: "success",
    message: "Your settings have been updated successfully"
  });
}</script>


<h1>PSE Settings</h1>
{#if $session.id}
  <p>Several competency frameworks are available, which you can use for self-paced learning. 
     You can enable these and add them to your tracker using the checkboxes below. 
     These additional technical areas are not, however, available for certification.
  </p>
  <Formrow labelwidth="0" id="cogan" label="COGAN Technical Areas" type="checkbox" bind:checked={$session.tracker_options.cogan}></Formrow>
  <Formrow labelwidth="0" id="femforging" label="FEMForging Technical Areas" type="checkbox" bind:checked={$session.tracker_options.femforging}></Formrow>
  <Formrow labelwidth="0" id="pressurepower" label="Pressure & Power Systems Industry" type="checkbox" bind:checked={$session.tracker_options.pressurepower}></Formrow>
  <!-- <Formrow labelwidth="0" id="advfluid" label="Advanced Fluid Flow" type="checkbox" bind:checked={$session.tracker_options.advfluid}></Formrow> -->
  <Button mode="primary" on:click={save}>Save Changes</Button>
{:else}
  <p>Loading settings...</p>
{/if}
