<script>import Modal from "../shared/modal.svelte";
import Grid from "../shared/grid.svelte";
import api from "../shared/api";
import Button from "../shared/button.svelte";
import Search from "../shared/search.svelte";
export let open = false;
export let postid;
let data = [];
let selected = [];
let filtered = [];

async function load() {
  data = (await api.load({
    url: `/duocms/api/forums-watched/${postid}`
  })).data;
}

async function unsubscribe() {
  let user_ids = selected.map(user => user.id);
  data = (await api.save({
    url: `/duocms/api/forums-unwatch/${postid}`,
    data: {
      user_ids
    }
  })).data;
}

load();</script>

<Modal bind:open={open} heading="Subscribers" style="width:500px;">
  <div style="margin:5px -5px;">
    <Search bind:data={data} bind:filtered={filtered} placeholder="Search Subscribers" />
  </div>
  <div style="height:500px;">
    <Grid data={filtered} bind:selected={selected} multiselect columns={[
      {label:"First Name",field:"first_name",width:4},
      {label:"Surname",field:"surname",width:4},
      {label:"Email",field:"email",width:5},
    ]}><div slot="footer">{data.length} Subscribers</div></Grid>
  </div>
  <div slot="toolbar" style="text-align:right">
    <Button disabled={!selected.length} mode="primary" on:click={unsubscribe}>Unsubscribe Selected</Button>
    <Button on:click={()=> open = false}>Close</Button>
  </div>
</Modal>