
    var riot = require('riot')
    
riot.tag2('resourcedate', '<formdate value="{val}" dateformat="dd/MM/yyyy" onupdate="{updating}" width2="col-xs-12"></formdate> <input id="savefield" type="hidden" name="{opts.name}" value="{value}">', '', '', function(opts) {
this.on('mount', (function(_this) {
  return function() {
    var d, m, y;
    _this.value = opts.value;
    if (!_this.value || _this.value === '' || _this.value === "0000-00-00") {
      _this.val = '';
    } else {
      y = _this.value.substr(0, 4);
      m = _this.value.substr(5, 2) - 1;
      d = _this.value.substr(8, 2);
      _this.val = new Date(y, m, d, 0, 0, 0);
    }
    return _this.update();
  };
})(this));

this.updating = (function(_this) {
  return function(elem) {
    _this.value = elem.value.getFullYear() + "-" + (elem.value.getMonth() + 1) + "-" + elem.value.getDate();
    return _this.savefield.setAttribute("value", _this.value);
  };
})(this);
});
    
  