<script>export let max = 100;
export let value = 0;
export let mode = "primary";</script>

<div class="sprogress">
  <div class="sbar {mode}" style="width:{(value/max)*100}%">
    <slot />
  </div>
</div>

<style>
  .sprogress{
    min-height:10px;
    background:rgba(0,0,0,0.03);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    border-radius:4px;
    border-radius:var(--s-border-radius,4px);
  }

  .sbar{
    border-radius:4px;
    border-radius:var(--s-border-radius,4px);
    transition:0.5s width;
    font-size:11px;
    line-height:18px;
    height:18px;
    text-align:center;
  }

  .primary,.info,.success,.warning,.danger,.dark{
    color:white;
    box-shadow:0 0 2px rgba(0,0,0,0.1),0 0px 0px rgba(0,0,0,0.1);
  }   
  .primary{background-color:#2299ee; background-color:var(--s-primary,#2299ee)}
  .info{background-color:#17a2b8; background-color:var(--s-info,#17a2b8)}
  .success{background-color:#28a745; background-color:var(--s-success,#28a745)}
  .warning{background-color:#ffc107; background-color:var(--s-warning,#ffc107)}
  .danger{background-color:#dc3545; background-color:var(--s-danger,#dc3545)}
  .dark{background-color:#343a40; background-color:var(--s-dark,#343a40)}
</style>
