
    var riot = require('riot')
    
riot.tag2('categories-tree', '<input type="hidden" value="{ticks.join(\',\')}" name="{opts.name}"> <ul class="treeview"> <categories-branch parentid="0" treedata="{data}" ticks="{ticks}" edit="{edit}" tickupdate="{tickupdate}" addfield="{addField}" removefield="{removeField}"></categories-branch> </ul> <button onclick="{startEdit}" if="{!edit}" class="btn btn-default">Edit Categories</button> <div if="{edit}" class="btn-toolbar"> <button onclick="{cancel}" class="btn btn-default">Cancel </button> <button onclick="{save}" class="btn btn-primary">Save Changes</button> </div>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.edit = false;

this["delete"] = [];

this.on('mount', (function(_this) {
  return function() {
    var ref;
    _this.ticks = ((ref = opts.value) != null ? typeof ref.split === "function" ? ref.split(",").map(function(id) {
      return +id;
    }) : void 0 : void 0) || [];
    return _this.load();
  };
})(this));

this.startEdit = (function(_this) {
  return function() {
    return _this.edit = true;
  };
})(this);

this.save = (function(_this) {
  return function() {
    var changecount;
    _this.edit = false;
    changecount = 0;
    _this.data.forEach(function(row, idx) {
      if (!row.id || row.name !== _this.diff[idx].name || row.parent_id !== _this.diff[idx].parent_id) {
        changecount += 1;
        return xhr("post", '/duocms/api/resourcecenter/categories/' + row.id, row, function(res) {
          changecount -= 1;
          if (changecount === 0) {
            return _this.load();
          }
        });
      }
    });
    return _this["delete"].forEach(function(item) {
      changecount += 1;
      return xhr("delete", '/duocms/api/resourcecenter/categories/' + item.id, null, function(res) {
        changecount -= 1;
        if (changecount === 0) {
          return _this.load();
        }
      });
    });
  };
})(this);

this.cancel = (function(_this) {
  return function() {
    _this.edit = false;
    return _this.load();
  };
})(this);

this.load = (function(_this) {
  return function() {
    return xhr("get", '/duocms/api/resourcecenter/categories', null, function(res) {
      _this.data = res.body.data;
      _this.diff = res.body.data.map(function(row) {
        return {
          name: row.name,
          id: row.id,
          parent_id: row.parent_id
        };
      });
      _this["delete"] = [];
      return _this.update();
    });
  };
})(this);

this.addField = (function(_this) {
  return function(row) {
    _this.data.push(row);
    return _this.update();
  };
})(this);

this.removeField = (function(_this) {
  return function(item) {
    var idx;
    if (item.id) {
      idx = _this.getIndexById(_this.data, item.id);
      if (idx > -1) {
        _this["delete"].push(item);
        _this.data.splice(idx, 1);
        _this.diff.splice(idx, 1);
      }
      return _this.update();
    }
  };
})(this);

this.getIndexById = function(coll, id) {
  var i, j, len, row;
  for (i = j = 0, len = coll.length; j < len; i = ++j) {
    row = coll[i];
    if (+row.id === +id) {
      return i;
    }
  }
  return -1;
};

this.getIndexesByParentId = function(coll, parent_id) {
  var i, indexes, j, len, row;
  indexes = [];
  for (i = j = 0, len = coll.length; j < len; i = ++j) {
    row = coll[i];
    if (+row.parent_id === +parent_id) {
      indexes.push(i);
    }
  }
  return indexes;
};

this.tickupdate = (function(_this) {
  return function(item) {
    return setTimeout(function() {
      var idx;
      idx = _this.ticks.indexOf(+item.id);
      if (idx < 0) {
        _this.tickAdd(item, idx);
      } else {
        _this.tickRemove(item, idx);
      }
      return _this.update();
    });
  };
})(this);

this.tickRemove = (function(_this) {
  return function(item, idx) {
    var children;
    children = _this.getIndexesByParentId(_this.data, item.id);
    children.forEach(function(idx) {
      var tickIdx;
      tickIdx = _this.ticks.indexOf(+_this.data[idx].id);
      if (tickIdx > -1) {
        return _this.tickRemove(_this.data[idx], tickIdx);
      }
    });
    return _this.ticks.splice(idx, 1);
  };
})(this);

this.tickAdd = (function(_this) {
  return function(item, postdata) {
    var index;
    if (+item.parent_id !== 0) {
      index = _this.getIndexById(_this.data, item.parent_id);
      if (index > -1) {
        _this.tickAdd(_this.data[index]);
      }
    }
    if (_this.ticks.indexOf(+item.id) === -1) {
      return _this.ticks.push(+item.id);
    }
  };
})(this);
});
    
  