
    var riot = require('riot')
    
riot.tag2('resource-popup', '<button onclick="{showmodal}" class="download"><yield></yield></button> <modal name="video-modal" title="{title}" onclose="{true}" closetext="Close" bssize="lg"> <div class="embed-responsive embed-responsive-16by9"> <iframe riot-src="{parent.url}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> </div> </modal>', '', '', function(opts) {
this.on("mount", (function(_this) {
  return function() {
    var err, ref;
    console.log(opts.resource);
    if (opts.resource.match(/flipsnack/)) {
      _this.title = "Flip Book";
      _this.url = (ref = opts.resource.match(/https:\/\/[^"]+/)) != null ? ref[0] : void 0;
      return _this.update();
    } else {
      _this.title = "Video";
      if (opts.resource) {
        try {
          return fetch("https://vimeo.com/api/oembed.json?url=" + opts.resource).then(function(res) {
            return res.json();
          }).then(function(res) {
            _this.url = res.html.match(/src="([^"]+)/)[1] + '&byline=false&color=#d00';
            _this.update();
            return window.__vimeoRefresh();
          });
        } catch (error) {
          err = error;
          return console.log(err);
        }
      }
    }
  };
})(this));

this.showmodal = (function(_this) {
  return function() {
    return _this.tags['video-modal'].show();
  };
})(this);
});
    
  