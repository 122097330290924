
    var riot = require('riot')
    
riot.tag2('twitter-bar', '<yield></yield> <div class="carousel slide"> <ol if="{opts.showDots}" class="carousel-indicators"> <li each="{tweet,i in tweets}" onclick="{parent.goto}" class="dot {active:i==parent.slide_index}"></li> </ol> <div riot-tag="twitter-list" riot-src="{opts.src}" count="{opts.count}" loaded="{loaded}" class="carousel-inner"> <div class="item {parent.parent.slideclass[i]}"><i class="fa fa-twitter fa-2x"></i><span riot-tag="raw" content="{tweet.text}" class="txt"></span> </div> </div> </div>', 'twitter-bar { display: block; position: relative; height: 40px; } twitter-bar .item { border-top: 1px solid #fff; margin-top: 5px; } twitter-bar .fa-twitter { color: #393a60; margin: 0; float: left; display: block; } twitter-bar .txt { display: block; font-size: 14px; margin: 5px 0 10px 30px; } twitter-bar .carousel-indicators { position: absolute; top: 10px; right: 5px; left: auto; width: auto; height: 25px; } twitter-bar .carousel-indicators .dot { background: #d3d6da; border-radius: 0; width: 20px; height: 20px; margin: 0 2px; } twitter-bar .carousel-indicators .dot.active { background: #949ba4; } twitter-bar.twitter-block { height: 200px; background: #fff; padding: 1px 10px; } twitter-bar.twitter-block:after { font: normal normal normal 140px/1 FontAwesome; -webkit-font-smoothing: antialiased; content: "\\f099"; position: absolute; top: 20px; right: 20px; pointer-events: none; opacity: 0.05; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=5)"; filter: alpha(opacity=5); } twitter-bar.twitter-block .slide { height: 160px; } twitter-bar.twitter-block .carousel-indicators { top: auto; bottom: 0; } @media screen and (min-width: 768px) { twitter-bar.twitter-block .carousel-indicators { bottom: 0px; } }', '', function(opts) {
this.slide_index = 0;

this.slideclass = ['active'];

this.total = 0;

this.animating = false;

this.paused = false;

this.on('mount', function() {
  this.total = this.root.querySelectorAll('.item').length;
  this.update();
  return this.autoplay();
});

this.loaded = (function(_this) {
  return function(tweets) {
    _this.tweets = tweets;
    _this.active = 0;
    _this.total = _this.tweets.length;
    if (_this.opts.autoplay != null) {
      _this.interval = setInterval(function() {
        return _this.next();
      }, _this.opts.autoplay);
    }
    return _this.update();
  };
})(this);

this.autoplay = (function(_this) {
  return function() {
    if (_this.opts.autoplay == null) {
      return;
    }
    if (_this.autoplayInterval != null) {
      clearInterval(_this.autoplayInterval);
    }
    return _this.autoplayInterval = setInterval(function() {
      return _this.next();
    }, _this.opts.autoplay);
  };
})(this);

this.pause = (function(_this) {
  return function() {
    if (_this.opts.autoplay == null) {
      return;
    }
    if (_this.autoplayInterval != null) {
      return clearInterval(_this.autoplayInterval);
    }
  };
})(this);

this.unpause = (function(_this) {
  return function() {
    if (_this.opts.autoplay == null) {
      return;
    }
    return _this.autoplay();
  };
})(this);

this.next = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.slide_index;
    _this.slide_index++;
    if (_this.slide_index >= _this.total) {
      _this.slide_index = 0;
    }
    if (_this.total > 1) {
      _this.slidenext();
    }
    return _this.autoplay();
  };
})(this);

this.slidenext = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active left';
    _this.slideclass[_this.slide_index] = 'next';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'next left';
      return _this.update();
    }, 20);
    _this.clearTimeouts();
    return _this.prevTimeout = setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.animating = false;
      _this.prevTimeout = null;
      return _this.update();
    }, 600);
  };
})(this);

this.clearTimeouts = (function(_this) {
  return function() {
    if (_this.prevTimeout) {
      clearTimeout(_this.prevTimeout);
    }
    if (_this.nextTimeout) {
      return clearTimeout(_this.nextTimeout);
    }
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.slide_index;
    _this.slide_index--;
    if (_this.slide_index < 0) {
      _this.slide_index = _this.total - 1;
    }
    if (_this.total > 1) {
      _this.slideprev();
    }
    return _this.autoplay();
  };
})(this);

this.slideprev = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active right';
    _this.slideclass[_this.slide_index] = 'prev';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'prev right';
      return _this.update();
    }, 20);
    _this.clearTimeouts();
    return _this.nextTimeout = setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.animating = false;
      _this.nextTimeout = null;
      return _this.update();
    }, 600);
  };
})(this);

this.goto = (function(_this) {
  return function(e) {
    if (_this.animating || _this.slide_index === e.item.i) {
      return;
    }
    _this.previndex = _this.slide_index;
    _this.slide_index = e.item.i;
    if (_this.slide_index < _this.previndex) {
      _this.slideprev();
    } else {
      _this.slidenext();
    }
    _this.update();
    return _this.autoplay();
  };
})(this);
});
    
  