
    var riot = require('riot')
    
riot.tag2('buydelegates', '<button if="{!opts.showqty}" type="button" onclick="{handleAdd}" class="btn btn-block btn-sm {opts.btnClass || \'btn-primary\'}">Book</button>', '', '', function(opts) {
var Formatter, resource, tagSelector;

resource = require('riot-kit/utils/apiResource.coffee');

tagSelector = require('riot-kit/utils/tagSelector.coffee');

Formatter = require('riot-kit/utils/dateformat.coffee');

this.on('mount', (function(_this) {
  return function() {
    _this.basket = tagSelector('basket', 'popup-basket');
    _this.basketResource = new resource(opts.src || '/duocms/api/basket');
    _this.delegateResource = new resource('/duocms/api/event_delegates/' + opts.productid, true);
    if (_this.basket) {
      return _this.basket._tag.on("update", _this.basketModalUpdated);
    } else {
      return _this.getDelegates();
    }
  };
})(this));

this.on('unmount', function() {
  if (this.basket) {
    return this.basket._tag.off("update", this.basketModalUpdated);
  }
});

this.handleAdd = (function(_this) {
  return function() {
    var ref;
    if (!((ref = _this.delegates) != null ? ref.length : void 0)) {
      _this.delegates = [
        {
          event_id: opts.eventid
        }
      ];
    }
    if (_this.basket) {
      return _this.delegateResource.post(_this.delegates, function(res, err) {
        if (err) {
          return _this.update({
            error: err.message
          });
        } else {
          _this.update({
            error: false
          });
          return _this.basketResource.save({
            id: opts.productid,
            qty: _this.delegates.length
          }, function() {
            _this.basket.trigger('updateBasket');
            return _this.tags.delegatemodal.hide();
          });
        }
      });
    }
  };
})(this);
});
    
  