
    var riot = require('riot')
    require('riot-kit/lib/popover');require('riot-kit/lib/form');require('riot-kit/lib/datepicker');
riot.tag2('formdateinput', '<forminput label="{opts.label}" value="{val}" name="{opts.name}" placeholder="{opts.placeholder}" buttonafter="&lt;i class=\'fa fa-calendar\'&gt;&lt;/i&gt;" width1="{opts.width1}" width2="{opts.width2}" buttonafterclick="{showcal}" onupdate="{handleTyping}" isrequired="{opts.isrequired}" pattern="{opts.pattern}"></forminput> <popover title="{opts.title}"> <datepicker onupdate="{parent.handleUpdate}" date="{parent.date}"></datepicker> </popover>', 'formdateinput forminput .fa.fa-calendar{ pointer-events:none; }', '', function(opts) {
var Formatter;

Formatter = require('riot-kit/utils/dateformat.coffee');

this.val = "";

this.prevValue = "";

this.on('update', function() {
  if (this.prevValue !== opts.value) {
    return this.setDate(opts.value);
  }
});

this.showcal = (function(_this) {
  return function(e) {
    return _this.tags.popover.clicked(e);
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(newdate, changetypes) {
    if (changetypes.indexOf('month') !== -1 || changetypes.indexOf('year') !== -1) {
      return;
    }
    _this.date = newdate;
    _this.setDate(_this.date);
    _this.prevValue = newdate;
    return _this.tags.popover.hide();
  };
})(this);

this.handleTyping = (function(_this) {
  return function(data) {
    var dt;
    dt = new Formatter();
    _this.date = dt.parseString(opts.dateformat, data.value);
    if (_this.date) {
      return _this.setDate(_this.date);
    } else {
      return _this.updateParent('');
    }
  };
})(this);

this.updateParent = (function(_this) {
  return function(newdate) {
    var name;
    if (opts.onupdate) {
      name = (opts.id != null) && opts.id.length ? opts.id : opts.name;
      return opts.onupdate.call(null, {
        name: name,
        value: newdate
      });
    }
  };
})(this);

this.setDate = (function(_this) {
  return function(date) {
    var dt;
    if (!opts.dateformat || !date || !(date instanceof Date)) {
      _this.val = date;
    } else {
      dt = new Formatter(date);
      _this.val = dt.format(opts.dateformat);
    }
    _this.updateParent(_this.val);
    return _this.update();
  };
})(this);
});
    
  