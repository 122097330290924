
    var riot = require('riot')
    
riot.tag2('dropboxsync', '<button onclick="{sync}" style="font-size:14px;padding:5px 30px;position:relative;" class="pull-right"><img src="/images/site_bits/resource_center/spin.gif/" show="{spin}" style="display:block;position:absolute;top:4px;left:5px;height:20px;"><yield></yield></button> <div if="{message}" class="message"> <p>{message}</p> </div>', 'dropboxsync { position: relative; } dropboxsync .message { background: rgba(0,0,0,0.8); padding: 15px; color: #fff; position: absolute; top: 45px; left: 290px; text-align: center; width: 300px; z-index: 100000; -webkit-box-shadow: 0 0 5px 5px rgba(255,255,255,0.8); box-shadow: 0 0 5px 5px rgba(255,255,255,0.8); } dropboxsync .message p { font-size: 18px; line-height: 30px; }', '', function(opts) {
this.spin = false;

this.message = false;

this.sync = (function(_this) {
  return function() {
    _this.spin = true;
    $.get('/duocms/nafems/resource_sync/', function(result) {
      _this.message = result;
      _this.spin = false;
      _this.update();
      return setTimeout((function() {
        return _this.update({
          message: ""
        });
      }), 4000);
    });
    return _this.update();
  };
})(this);
});
    
  