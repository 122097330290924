
    var riot = require('riot')
    
riot.tag2('mypassword', '<div if="{success}" class="alert alert-success">{successMessage}</div> <form hide="{success}" class="form-horizontal"> <forminput name="password" label="Password" onupdate="{handleUpdate}" type="password" required width1="2" width2="10"></forminput> <forminput name="passwordConfirm" label="Retype Password" onupdate="{handleUpdate}" type="password" required min="8" width1="2" width2="10"></forminput> <p><small>Passwords must be at least 8 characters long</small></p> <div class="row"> <div class="col-xs-10 col-xs-offset-2"> <div if="{error}" class="alert alert-danger">{errorMessage.message}</div> <button onclick="{submit}" __disabled="{submitting}" class="btn btn-primary"> <span show="{submitting}" class="fa fa-refresh fa-spin"> </span> Set Password</button> </div> </div> </form>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource(opts.src || '/duocms/api/users/me', true);

this.user = {};

this.handleUpdate = (function(_this) {
  return function(input) {
    return _this.user[input.name] = input.value;
  };
})(this);

this.on("mount", function() {
  if (window.location.search.match(/editmode=/)) {
    return this.root.innerHTML = "--password form--";
  }
});

this.submit = (function(_this) {
  return function() {
    if (!_this.user.password || _this.user.password.length < 5) {
      return _this.showError({
        message: 'Your password is not long enough'
      });
    } else if (_this.user.password === _this.user.passwordConfirm) {
      _this.error = false;
      _this.success = false;
      _this.submitting = true;
      _this.update();
      return api.save(_this.user, function(user, err) {
        _this.submitting = false;
        if (err != null) {
          _this.showError(err);
        }
        if (err == null) {
          _this.showSuccess('Your password has been successfully changed');
          return setTimeout(function() {
            if (opts.onsuccess != null) {
              return window.location.href = opts.onsuccess;
            }
          }, 2000);
        }
      });
    } else {
      return _this.showError({
        message: 'Passwords do not match'
      });
    }
  };
})(this);

this.showError = (function(_this) {
  return function(errorMessage) {
    _this.errorMessage = errorMessage;
    _this.error = true;
    return _this.update();
  };
})(this);

this.showSuccess = (function(_this) {
  return function(successMessage) {
    _this.successMessage = successMessage;
    _this.success = true;
    return _this.update();
  };
})(this);
});
    
  