window.riot = require('riot')
require('./categories.tag')
require('./wrapper.tag')
require('./category.tag')
require('./searchform.tag')
require('./searchresults.tag')
require('./searchpage.tag')
require('./detailpicker.tag')
require('./rcpagination.tag')
require('./resourcedate.tag')
require('./categories-tree.tag')
require('./categories-branch.tag')
require('./dropboxsync.tag')
require('riot-kit/lib/formdate')
require('./resources.js')



riot.mount('searchpage,wrapper,dropboxsync')
