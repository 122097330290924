<!--
  @component
  ## Alert
  Closeable alert banner

  Examples:
  ```html
    <Alert>Message</Alert>
    <Alert mode="success">Something worked!</Alert>
    <Alert mode="danger" close on:closed={doSomething}>Your Message</Alert>
  ```
-->
<script>/*** @type {"info" | "warning" | "danger" | "success" | "dark"} ***/
export let mode = "info";
/*** @type {boolean} ***/

export let close = null;
export let style = "";
import { createEventDispatcher } from 'svelte';
let alertBox;
const dispatch = createEventDispatcher();

function handleClose(e) {
  alertBox = e.target.closest(".salert"); // bind:this not working in repl

  alertBox.style.maxHeight = alertBox.offsetHeight + "px";
  setTimeout(() => {
    alertBox.classList.add("hiding");
  });
  setTimeout(() => {
    dispatch('closed');
  }, 500);
}</script>

<div class="salert {mode}" role="alert" style={style}> 
  {#if close}
    <button type="button" class="close" on:click={handleClose} aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  {/if}
  <slot />
</div>

<style>
  *{
    box-sizing: border-box;
  }

.salert{
  padding:12px 15px;
  position:relative;
  color:var(--alert-color,#444);
  margin:10px 0px;
  border-radius:5px;
  isolation:isolate;
  transition:max-height 0.5s,opacity 0.5s,padding 0.5s,margin-bottom 0.5s,transform 0.5s;
}
.salert:first-child{
  margin-top:0!important
}
.salert:last-child{
  margin-bottom:0!important
}



@supports(background-color:color-mix(in srgb, var(--alert-color) 20%, white)){

  .salert.info{
    --alert-color:var(--alert-info,#d9edf7);
  }
  .salert.warning{
    --alert-color:var(--alert-warning,#ffe69c);
  }
  .salert.success{
    --alert-color:var(--alert-success,#ccf8e4);
  }
  .salert.danger{
    --alert-color:var(--alert-danger,#ffd9dd);
  }
  .salert.dark{
    --alert-color:var(--alert-dark,#ced4da);
  }
  .salert{
    background:var(--alert-color);
    color:color-mix(in lch, var(--alert-color) 0%, lch(40 40 none));
    border:1px solid color-mix(in lch, var(--alert-color) 0%, lch(85 20 none));
  }
}


.salert :global(h1),.salert :global(h2),.salert :global(h3),.salert :global(h4),.salert :global(h5),.salert :global(h6),.salert :global(p){
  margin:0 0 10px 0;
}

  .close{
    background:transparent;
    border:0px;
    font-size:24px;
    font-weight:bold;
    position:absolute;
    right:0px;
    top:0px;
    padding:4px 10px;
    cursor:pointer;
    opacity:0.5
  }
  .close:hover{
    opacity:1;
  }
  .hiding{
    overflow:hidden;
    max-height:0!important;
    opacity:0;
    padding:0 15px;
    margin-bottom:0;
    transform-origin: top;
    transform: scale(1,0)
  }



/* OLD CSS BELOW - can be removed when color-mix is better supported */
@supports not (background-color:color-mix(in srgb, var(--alert-color) 20%, white)){
  .salert.info{
    --alert-color:#055160 
  }
  .salert.warning{
    --alert-color:#664d03
  }
  .salert.success{
    --alert-color:#0f5132
  }
  .salert.danger{
    --alert-color:#842029
  }
  .salert.dark{
    --alert-color:#141619
  }

  .salert:after{
    content:'';
    position:absolute;
    inset:0;
    background:var(--alert-color,#444);
    border-radius:inherit;
    filter:brightness(240%) saturate(2) opacity(0.25);
    z-index:-1;
  }
  .salert:before{
    content:'';
    position:absolute;
    inset:0;
    border:1px solid var(--alert-color,#444);
    border-radius:inherit;
    filter:brightness(200%) saturate(2) opacity(0.2);
    z-index:-1;
    background:white;
  }
}
</style>