<script>export let currency = "GBP";
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Europe/London 

let language = navigator.language; //en-GB = uk, en-US = usa, 

let suggest = "";
let symbols = {
  "GBP": "£",
  "USD": "$",
  "EUR": "€"
};

async function setCurrency(code) {
  await fetch(`/duocms/api/basket?currency=${code}`);
  window.location.reload();
}

if (language == "en-GB" && timezone.match(/london/i) && currency != "GBP") {
  suggest = "GBP";
}

if (!timezone.match(/london/i) && timezone.match(/europe/i) && currency != "EUR") {
  suggest = "EUR";
}

if (language == "en-US" && timezone.match(/america/i) && currency != "USD") {
  suggest = "USD";
}</script>

{#if suggest}
  <div class="alert alert-warning">
    {#if suggest=="GBP"}
      <p>Your currency is currently set to {symbols[currency]}({currency}), but you appear to be purchasing from a UK timezone</p>
      <br>
      <button class="btn btn-warning btn-sm" on:click={()=> setCurrency("GBP")}> Click here to use £(GBP)</button>
    {/if}

    {#if suggest=="EUR"}
      <p>Your currency is currently set to {symbols[currency]}({currency}), but you appear to be purchasing from a European Country</p>
      <button class="btn btn-warning btn-sm" on:click={()=> setCurrency("EUR")}> Click here to use €(EUR)</button>
    {/if}

    {#if suggest=="USD"}
      <p>Your currency is currently set to {symbols[currency]}({currency}), but you appear to be purchasing from a US timezone</p>
      <button class="btn btn-warning btn-sm" on:click={()=> setCurrency("USD")}> Click here to use $(USD)</button>
    {/if}
  </div>
{/if}