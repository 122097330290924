// This script is used to save & load the progress of the user in the training videos & quizzes
document.addEventListener('DOMContentLoaded', function() {
  if(window.location.search.includes('editmode')) return
  let alerted = []
  let hasTicks = false
  if(window.location.pathname.startsWith("/training/learning-hub/")) loadProgress()
  // load completed tasks for this page
  async function loadProgress(){
    let url = "/duocms/api/training/progress"
    let path = window.location.pathname
    let qs = new URLSearchParams({path})
    let response = await fetch(`${url}?${qs}`);
    let json = await response.json()
    let resourceCount = (
      document.querySelectorAll("iframe[src^='https://player.vimeo.com']").length +
      document.querySelectorAll("iframe[src^='/downloads/dropbox/learninghub/quizzes/']").length +
      document.querySelectorAll("quiz-tag").length
    )
    // reset everything first
    document.querySelectorAll('.completed-tick').forEach((el)=>{
      el.classList.remove('completed-tick')
    })
    
    json.data.forEach((item)=>{
      if(item.type=="video" || item.type=="quiz"){
        document.querySelectorAll('iframe[src="'+item.url+'"]').forEach((iframe)=>{
          iframe.parentNode.classList.add('completed-tick')     
        })
      }else if(item.type=="complete"){
        document.querySelectorAll('training-complete').forEach((tag)=>{
          tag.setAttribute('completed',true)
        })
      }else{
        document.querySelectorAll('quiz-tag').forEach((a)=>{
          item.url == a.getAttribute('id') ? a.classList.add('completed-tick') : null
        })
      }
    })
    document.querySelectorAll('training-complete').forEach((tag)=>{
      tag.setAttribute('completed',json.data.length)
      tag.setAttribute('total',resourceCount)
    })
  }

  Array.from(document.querySelectorAll('iframe[src*="vimeo"]')).forEach(iframe => {
    let player = new Vimeo.Player(iframe);
    player.on('timeupdate', (event)=>{
      let src = iframe.getAttribute('src')
      if(event.percent > 0.9 && !alerted.includes(src)){
        alerted.push(src)
        saveProgress({src:src,type:"video"})
      }
    });
  })

  // setup code to check progress of old quizzes
  let quizframes = Array.from(document.querySelectorAll('iframe[src*="/downloads/dropbox/learninghub/quizzes/"]'))
  if(quizframes.length>0){
    setInterval(() => {
      quizframes.forEach(iframe => {
        let src = iframe.getAttribute('src')
        let result = iframe.contentDocument.querySelector('.questionset-results .h5p-joubelui-score-bar-progress')
        if(result && parseInt(result.style.width,10) > 49 && !alerted.includes(src)){
          saveProgress({src:src,type:"quiz"})
        }
      })
    }, 1000);
  }

  // setup code to check progress of new quizzes
  window.addEventListener('quizcomplete', (e) => {
    let quiz = e.detail
    saveProgress({src:""+quiz.id, type:"quiztag"})
    loadProgress()
  })

  async function saveProgress({src,type}){
    let url = "/duocms/api/training/progress"
    let path = window.location.pathname
    let data = {url:src,type,path}
    let response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': window.csrf },
      body: JSON.stringify(data),
    });
    loadProgress()
  }

})
