
    var riot = require('riot')
    
riot.tag2('searchpage', '<yield></yield>', '', '', function(opts) {
this.handleUpdateResults = (function(_this) {
  return function(res) {
    _this.results = res;
    return _this.update();
  };
})(this);
});
    
  