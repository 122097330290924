
    var riot = require('riot')
    
riot.tag2('training_details', '<raw content="{opts.opts.training_details}"></raw> <ul class="nav nav-tabs"> <li each="{ref, i in opts.psedata.trainings}" class="{active:parent.training_id == i}"><a href="./training_details/{i+1}" class="heading btn">{i+1}</a></li> <li><a onclick="{addTraining}" class="btn btn-success">Add Training</a></li> <li> <confirm onconfirm="{deleteTraining}"><a class="btn btn-danger">Delete Training</a></confirm> </li> </ul> <div class="tab-content"> <div class="tab-pane {active: training}"> <div class="col-xs-10 col-xs-offset-1"> <form class="form-horizontal"> <popover parent="{infoParent}"> <raw content="{parent.info}"></raw> </popover> <forminput label="Date*" name="edu_date_start" value="{training.edu_date_start}" onupdate="{handleUpdate}" isrequired></forminput> <forminput label="Duration of Training" name="edu_duration" value="{training.edu_duration}" onupdate="{handleUpdate}"></forminput> <forminput label="Title of Course" name="edu_qualification" value="{training.edu_qualification}" onupdate="{handleUpdate}"></forminput> <forminput label="Principal Subjects/Occupational Skills Covered" name="edu_subject" value="{training.edu_subject}" onupdate="{handleUpdate}"></forminput> <forminput label="Training Organisation Name" name="edu_organisation" value="{training.edu_organisation}" onupdate="{handleUpdate}"></forminput> <forminput label="Training Organisation Type" name="edu_orgtype" value="{training.edu_orgtype}" onupdate="{handleUpdate}" buttonafter="{infobutton}" buttonafterclick="{showInfo.bind(null, opts.infos.training.edu_orgtype)}"></forminput> <forminput label="National/International Classification Level - if applicable" name="edu_intlevel" value="{training.edu_intlevel}" onupdate="{handleUpdate}"></forminput> <fileinput label="Upload File" files="{trainingFiles}" onupdate="{handleUpload}" onremove="{handleRemove}"></fileinput> </form> </div> </div> </div>', '', '', function(opts) {
this.trainings = [];

this.infobutton = "<span class=\"fa fa-info\" style=\"pointer-events:none\"></span>";

this.mounted = false;

this.on('mount', function() {
  this.mounted = true;
  if (opts.errors != null) {
    return setTimeout((function(_this) {
      return function() {
        return _this.checkErrors();
      };
    })(this));
  }
});

this.showInfo = (function(_this) {
  return function(info, e) {
    _this.info = info;
    _this.infoParent = e.target;
    _this.tags.popover.clicked(e);
    return _this.update();
  };
})(this);

this.on('update', function() {
  if (opts.files != null) {
    this.files = opts.files.filter(function(f) {
      return f.path.match(/^tr_/);
    });
  }
  if ((opts.errors != null) && this.mounted) {
    setTimeout((function(_this) {
      return function() {
        return _this.checkErrors();
      };
    })(this));
  }
  if (opts.psedata != null) {
    return this.load();
  }
});

this.checkErrors = (function(_this) {
  return function() {
    _this.trainingErrors = opts.errors.filter(function(e) {
      return e.tab === 'trainings';
    }).map(function(e) {
      return e.field;
    });
    _this.update();
    return _this.trainingErrors.forEach(function(field) {
      return _this.tags[field].tags['formgroup'].trigger('validate');
    });
  };
})(this);

this.load = function() {
  var ref, regex;
  this.trainings = opts.psedata.trainings;
  this.training_id = opts.params.training_id != null ? opts.params.training_id - 1 : 0;
  if (((ref = this.trainings) != null ? ref[this.training_id] : void 0) != null) {
    this.training = this.trainings[this.training_id];
  }
  regex = new RegExp('tr_' + (this.training_id + 1) + '_');
  this.trainingFiles = this.files.filter(function(f) {
    return f.path.match(regex);
  });
  return this.update();
};

this.addTraining = function() {
  this.trainings.push({});
  opts.page.redirect('training_details/' + this.trainings.length);
  return this.update();
};

this.deleteTraining = (function(_this) {
  return function() {
    _this.trainings.splice(_this.training_id, 1);
    opts.page.redirect('training_details/' + _this.trainings.length);
    return _this.update();
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(val) {
    _this.training[val.name] = val.value;
    return opts.onupdate('trainings', _this.training_id, _this.training);
  };
})(this);

this.handleUpload = (function(_this) {
  return function(val) {
    var data, file;
    if ((val != null ? val[0] : void 0) != null) {
      file = val[0];
      data = new FormData();
      data.append('file', file);
      data.append('name', 'tr_' + (_this.training_id + 1) + "_" + file.name);
      return fetch('/duocms/api/pse/file', {
        method: 'POST',
        credentials: "include",
        headers: {
          'x-csrf-token': window.csrf
        },
        body: data
      }).then(function() {
        return opts.onupdate('files', 'add', {
          path: 'tr_' + (_this.training_id + 1) + "_" + file.name
        });
      });
    }
  };
})(this);

this.handleRemove = (function(_this) {
  return function(file) {
    return fetch('/duocms/api/pse/file?path=' + file.path, {
      method: 'DELETE',
      credentials: "include",
      headers: {
        'x-csrf-token': window.csrf
      }
    }).then(function() {
      return opts.onupdate('files', 'remove', file);
    });
  };
})(this);
});
    
  