<script>import { Button, Grid, Route, navigate, Formrow, Confirm, api, toasterStore } from "duo-kit";
import TeamInvite from "./team-invite.svelte";
import TeamRename from "./team-rename.svelte";
import TeamAddUsers from "./team-addusers.svelte";
import { teams, session } from "./store.js";
session.load();
let selected = [];
let selectedteam = [];
let teammembers = [];

async function handleRemove(e) {
  for (let member of selected) {
    await api.remove({
      url: `/duocms/api/tracker/teammember/${member.id}`
    });
  }

  getTeam();
}

function handleTeamRemove() {
  let id = selectedteam[0].id;
  api.remove({
    url: `/duocms/api/tracker/teams/${id}`
  });
  teams.remove(id);
}

async function resendInvite() {
  let id = selected[0].id;
  if (id) await api.load({
    url: `/duocms/api/tracker/resendinvite/${id}`
  });
  toasterStore.add({
    title: "Invite ReSent",
    type: "success",
    message: "Your Invite has been resent"
  });
}

async function getTeam() {
  let id = selectedteam[0].id;
  teammembers = (await api.load({
    url: `/duocms/api/tracker/teams/${id}`
  })).data.users;
}

async function load() {
  await teams.load();

  if ($teams.length > 0 && !selectedteam.length) {
    selectedteam[0] = $teams[0];
    getTeam();
  }
}

async function updateType(e) {
  let id = selected[0].id;
  await api.save({
    url: `/duocms/api/tracker/teammember`,
    data: {
      id,
      type: e.target.value
    }
  });
  await getTeam();
}

async function addMembers(e) {
  // for admins only
  navigate(`/pseteams/${selectedteam[0].id}/addmembers/`);
}

load();</script>



<h1>My PSE Teams</h1>

<div class="row" style="--s-button-margins:5px">
  <div class="col-xs-12 col-sm-3">
    <div>
      <div class="btn-toolbar">
        <Button mode="default" on:click={()=> navigate("/pseteams/add/")}>Add Team</Button> 
        <Button mode="default" disabled={!selectedteam.length} on:click={()=> navigate(`/pseteams/${selectedteam[0].id}/rename`)}>Rename</Button> 
        <Confirm mode="default" disabled={!selectedteam.length} title="Delete Team?" on:confirm={handleTeamRemove}>Delete</Confirm> 
      </div>
      <div style="height:500px">
        <Grid 
          rowheight={30} data={$teams} 
          bind:selected={selectedteam}
          on:click={getTeam}
          on:dblclick={()=> navigate(`/pseteams/${selectedteam[0].id}/rename`)}
          columns={[
            {label:"ID",field:"id",width:1},
            {label:"Name",field:"name",width:5},
          ]}
        > 

        <div slot="row" let:item>
          <div class="cell" style="flex:1" >{item.id}</div>
          <div class="cell" style="flex:5" >{item.name}</div>
        </div> 

        <div slot="footer" let:data>
          {data.length} {data.length == 1 ? 'Item' : 'Items'} {selectedteam.length ? "( "+selectedteam.length+" selected )" : ""} 
        </div>
        </Grid>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-sm-9">
    <div>
      <div class="btn-toolbar">
        <Button mode="default" disabled={!selectedteam.length} on:click={()=> navigate(`/pseteams/${selectedteam[0].id}/invite/`)}>Invite New Team Members</Button> 
        <Confirm mode="default" disabled={!selected.length} on:confirm={handleRemove}>Remove Selected</Confirm> 
        <Button mode="default" on:click={resendInvite} disabled={!selected.length || selected[0].type!="invite"}>Resend Invite</Button>
        {#if $session && $session.permissions && $session.permissions.includes("users_all")}
          <Button mode="default" on:click={addMembers} >Add Team Members *</Button> 
        {/if}
        <Formrow type="select" disabled={!selected.length || selected[0].type=="owner" || selected[0].type=="invite"} style="width:120px;float:right;" value={selected[0] ? selected[0].type : ""} on:change={updateType}>
          <option value="member">Member</option>
          <option value="manager">Manager</option>
        </Formrow>
      </div>
      <div style="height:500px">
        <Grid 
          rowheight={30} data={teammembers} 
          bind:selected
          multiselect
          columns={[
            {label:"First Name",field:"first_name",width:4},
            {label:"Surname",field:"surname",width:4},
            {label:"Organisation",field:"org_name",width:4},
            {label:"Email",field:"email",width:5},
            {label:"Type",field:"type",width:2}
          ]}
        > 

        <div slot="row" let:item>
          <div class="cell" style="flex:4" >{item.first_name || ""}</div>
          <div class="cell" style="flex:4" >{item.surname || ""}</div>
          <div class="cell" style="flex:4" >{item.org_name || ""}</div>
          <div class="cell" style="flex:5" >{item.email || item.invite_email}</div>
          <div class="cell" style="flex:2" >{item.type}</div>
        </div> 

        <div slot="footer" let:data>
          {data.length} {data.length == 1 ? 'Item' : 'Items'} {selected.length ? "( "+selected.length+" selected )" : ""} 
        </div>
        </Grid>
      </div>
    </div>
  </div>
</div>

<Route path=":teamid/invite" let:params>
  <TeamInvite teamid={params.teamid} on:saved={getTeam} />
</Route>

<Route path="add">
  <TeamRename />
</Route>

<Route path=":teamid/rename" let:params>
  <TeamRename id={params.teamid}/>
</Route>

<Route path=":teamid/addmembers" let:params>
  <TeamAddUsers id={params.teamid} on:saved={getTeam} />
</Route>

{#if $session && $session.permissions && $session.permissions.includes("users_all")}
<div class="row"><p><small>* Only available to Nafems Admin</small></p></div>
{/if}