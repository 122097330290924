<script>import { onMount } from "svelte";
/*** @type {int} ***/

export let duration = 3;</script>

<div class="chart">
  <svg width="100%" height="100%" viewBox="2 2 36 36" class="donut">
    <circle class="ring" cx="20" cy="20" r="16" stroke="currentColor"></circle>
    <circle class="ring segment" style={"--duration:"+duration+"s"} cx="20" cy="20" r="16" stroke="currentColor"></circle>
  </svg>
  <div><slot /></div>
</div>

<style>
  .chart {
    display:inline-grid;
  }
  .chart > * {
    grid-column:1 / 2;
    grid-row:1 / 2;
    align-self:center;
    justify-self: center;
  }

  .ring {
    opacity: 0.5;
    stroke-width:3;
    fill:transparent;
  }

  .segment {
    opacity: 1;
    transform-origin: center;
    stroke-dasharray: 0, 100;
    stroke-dashoffset:25;
    animation: donut var(--duration,3s) linear forwards;
  }

  @keyframes donut {
    to {
      stroke-dasharray: 100, 0;
    }
  }
</style>