<script>export let data = [];
export let selected = [];
export let columns = [];
export let orderby = "";
export let rowheight = 30;
export let direction = "up";
export let multiselect = false;
export let draggable = false;
import VirtualList from './virtual.svelte';
import { onMount, createEventDispatcher } from "svelte";
let hasMounted = false;
let isDragging = false;
let dragOverElem = null;
const SLOTS = $$slots; // HACK!! until https://github.com/sveltejs/svelte/issues/2106 is resolved

const dispatch = createEventDispatcher();
let collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});
onMount(() => {
  hasMounted = true; // hack for virtual list??
});

const resort = () => {
  if (orderby) {
    if (direction == "up") {
      return data.slice().sort((a, b) => collator.compare(a[orderby], b[orderby]));
    }

    return data.concat().sort((b, a) => collator.compare(a[orderby], b[orderby]));
  } else if (data) {
    return data.slice();
  }
};

const reorder = field => {
  if (orderby == field) {
    if (direction == "up") {
      direction = "down";
    } else if (direction == "down") {
      direction = "";
      orderby = "";
      return;
    }
  } else {
    direction = "up";
  }

  orderby = field;
};

const select = (e, item) => {
  if (multiselect && e.shiftKey && selected.length) {
    let last = selected[selected.length - 1]; // get last selected

    let start = sortedData.indexOf(last); // get index of last in data

    let end = sortedData.indexOf(item); // get current index in data

    for (let i = Math.min(start, end); i <= Math.max(start, end); i++) {
      let item = sortedData[i];
      selected = selected.includes(item) ? selected : selected.concat(item); // only add new rows
    }

    document.getSelection().removeAllRanges(); // deselect text
  } else if (multiselect && e.metaKey) {
    // toggle items in and out of list with meta key
    selected = selected.includes(item) ? selected.filter(row => row != item) : selected.concat(item);
  } else {
    // just set one item with no keys
    selected = [item];
  }

  dispatch('click', selected);
};

function handleDragStart(e, item) {
  isDragging = item; // e.dataTransfer.setData("text/plain", e.target.id);

  e.dataTransfer.effectAllowed = "move";
}

function handleDragOver(e) {
  if (dragOverElem) {
    dragOverElem.classList.remove("hover");
  }

  dragOverElem = e.target;
  dragOverElem.classList.add("hover");
  e.dataTransfer.dropEffect = "move";
}

function handleDropBefore(e, item) {
  let idx = sortedData.indexOf(item);
  dispatch("drop", {
    before: item,
    after: sortedData[idx - 1],
    item: isDragging
  });
}

function handleDropAfter(e, item) {
  let idx = sortedData.indexOf(item);
  dispatch("drop", {
    before: sortedData[idx + 1],
    after: item,
    item: isDragging
  });
}

function handleDragEnd(e) {
  isDragging = false;

  if (dragOverElem) {
    dragOverElem.classList.remove("hover");
    dragOverElem = null;
  }
}

$: sortedData = resort(data, orderby, direction); // fields in function call cause sortedData to update


$: selected = selected.filter(row => data.includes(row)); // removing rows from selected if they get removed from data</script>

<div class="grid" role="grid">
  <div class="headerrow">
    {#each columns as column}
      <div class="header" on:click={(e)=> reorder(column.field)} style={"flex:"+column.width}>
        {column.label}
        {#if orderby==column.field}
          <span class="arrow" class:flip="{direction == "up"}">&blacktriangledown;</span>
        {/if}
      </div>
    {/each}
  </div>

  <div class="rows">
    {#if hasMounted}
      <VirtualList itemHeight={rowheight} items={sortedData} let:item>
        <div class="gridrow" role="row" draggable={draggable ? "true" : null } on:dragstart={(e)=> handleDragStart(e,item)} on:dragend={handleDragEnd} class:odd={sortedData.indexOf(item)%2} class:active={selected.indexOf(item)>-1} 
            on:click={(e)=>{ select(e,item)}}
            on:dblclick={(e)=>{ selected=[item]; dispatch('dblclick',selected) }}
            style="height:{rowheight}px;"
          >
          
          <slot name="row" {item}>
            {#each columns as column}
              <div class="cell" role="cell" style={"flex:"+column.width}>{item[column.field]}</div>
            {/each}
          </slot>
          {#if isDragging}
            <div class="dragover_top"  on:dragover|preventDefault={handleDragOver} on:drop|preventDefault={(e)=> handleDropBefore(e,item)} />
            <div class="dragover_bottom" on:dragover|preventDefault={handleDragOver} on:drop|preventDefault={(e)=> handleDropAfter(e,item)} />
          {/if}
        </div>
      </VirtualList>
    {/if}
  </div>
  {#if SLOTS && SLOTS.footer}
    <div class="gridfooter">
      <slot name="footer" {data}></slot>
    </div>
  {/if}
  <slot></slot>
</div>

<style>
  * {
    box-sizing: border-box;
  }
  .grid{
    height:100%;
    min-height:100px;
    display:flex;
    flex-direction: column;
    border:var(--duo-border,1px solid #ccc);
  }
  .rows{
    flex:1;
    height:calc(100% - 70px);
    cursor:pointer;
    position:relative;
    overflow:hidden;
  }
  .headerrow,.gridrow,:global(.virtual-row .gridrow [slot="row"]){
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    position:relative;
  }
  :global(.virtual-row .gridrow [slot="row"]){
    flex:1  
  }
  .headerrow,.gridfooter{
    border:var(--duo-border,1px solid #ccc);
    border-width:0 0 1px 0;
    background:var(--duo-gridhead,linear-gradient(to bottom, #fff 0, #eee 100%));
  }
  .gridfooter{
    border-width:1px 0 0 0;
    padding:7px;
    font-size:0.9em; 
    height:30px;
  }
  .header{
    padding:7px;
    font-size:0.9em;
    font-weight: bold;
    cursor:pointer;
  }
  .gridrow :global(.cell){
    padding:7px;
    font-size:0.9em;
    overflow:hidden;
    white-space: nowrap;
    width: 0; /** fixes column expansion bug **/
  }
  /* :global([slot="row"]){
    display:contents;
  } */
  .gridrow{
    background:var(--duo-background,white);
  }
  .gridrow.odd{
    background:var(--duo-oddrow,#f3f3f3);
  }
  .gridrow.active{
    background-color:#22aae3; 
    color:white;
  }
  .arrow.flip{
   transform:rotate(180deg) scaleX(-1);
   display:inline-block;
  }

  .dragover_top,.dragover_bottom{
    background-color:rgba(0,0,0,0); 
    position:absolute;
    left:0;
    right:0;
    height:15px;
    z-index:100;
  }
  .dragover_top{
    top:-2px;
  }
  .dragover_bottom{
    bottom:-2px;
  }
  .dragover_top:global(.hover){
    border-top:4px solid #22aae3; 
  }
  .dragover_bottom:global(.hover){
    border-bottom:4px solid #22aae3; 
  }

</style>
