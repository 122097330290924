<script>import { IconSpinner, Filedrop, IconArrowDown, IconArrowUp, api } from "duo-kit";
let uploading = false,
    results = null;

async function handleDropFiles(e) {
  if (e.detail.files) {
    uploading = true;

    try {
      await api.upload({
        url: "/duocms/api/files?action=upload&_csrf=" + window.csrf,
        file: e.detail.files[0],
        path: "/downloads/pse-tracker/",
        name: "upload.xlsx"
      });
    } catch (err) {}

    results = (await api.load({
      url: "/duocms/api/tracker/import"
    })).data;
    uploading = false;
  }
}</script>
<h2>Import / Export</h2>
<p>Use the tools below to import and export the competencies and resources</p>

<a class="btn btn-primary" href="/duocms/api/tracker/export"><IconArrowDown /> Download Excel</a>

{#if uploading}
  <button class="btn btn-primary" href="/duocms/api/tracker/export"><IconSpinner />  Uploading</button>
{:else}
  <Filedrop let:files class="btn btn-primary" clickable on:updated={handleDropFiles}>
    <IconArrowUp />  Upload Excel
  </Filedrop>
{/if}

{#if results}
  <hr>
  <h4>Upload Complete</h4>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Type</th>
        <th>Updated</th>
        <th>Added</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Competencies</td>
        <td>{results.competence_updated}</td>
        <td>{results.competence_added}</td>
      </tr>
      <tr>
        <td>Resources</td>
        <td>{results.resource_updated}</td>
        <td>{results.resource_added}</td>
      </tr>
    </tbody>
  </table>
{/if}
