
    var riot = require('riot')
    
riot.tag2('rcpagination', '<div if="{pages.length &gt; 1}"><span each="{page in pages}" class="formButton pagination"><a href="#" onclick="{parent.setPage.bind(null,page)}" class="{\'active\':parent.currentpage != page}">{page+1}</a></span></div>', '', '', function(opts) {
this.perpage = opts.perpage || 10;

this.max = opts.max || 10;

this.pages = [];

this.currentpage = 0;

this.start = 0;

this.end = 0;

this.data = [];

this.total = 0;

this.next = (function(_this) {
  return function() {
    if (currentpage < _this.total) {
      _this.currentpage++;
      return _this.update();
    }
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (currentpage > 0) {
      _this.currentpage--;
      return _this.update();
    }
  };
})(this);

this.setPage = (function(_this) {
  return function(pagenum) {
    _this.currentpage = pagenum;
    return _this.update();
  };
})(this);

this.on('update', (function(_this) {
  return function() {
    var i, ref, ref1, results;
    if (opts.data && opts.data.length) {
      _this.total = Math.ceil(opts.data.length / _this.perpage);
      _this.start = _this.perpage * _this.currentpage;
      _this.end = _this.start + +_this.perpage;
      if (opts.data && _this.end > opts.data.length) {
        _this.end = opts.data.length;
      }
      if (_this.start < 0 || _this.start > opts.data.length) {
        _this.start = 0;
      }
      _this.data = opts.data.slice(_this.start, _this.end);
    } else {
      _this.total = 0;
    }
    if (_this.currentpage > (_this.max / 2) && _this.max < _this.total) {
      _this.first = _this.currentpage - _this.max / 2;
    } else {
      _this.first = 0;
    }
    if (_this.first + _this.max > _this.total) {
      _this.first = _this.total - _this.max;
      if (_this.first < 0) {
        _this.first = 0;
      }
    }
    if (_this.data) {
      _this.pages = (function() {
        results = [];
        for (var i = ref = _this.first, ref1 = _this.total; ref <= ref1 ? i < ref1 : i > ref1; ref <= ref1 ? i++ : i--){ results.push(i); }
        return results;
      }).apply(this);
    }
    return opts.updatepages(_this.data);
  };
})(this));
});
    
  