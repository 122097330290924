import { writable } from 'svelte/store';
import {api} from "duo-kit"

function createStore(url){
	const { subscribe, set, update } = writable([]);
	let loaded = false
	return {
		subscribe,
		set,
		load: async ({force = false,qs}={}) => {
			let fullurl = url
			if(qs) fullurl+="?"+new URLSearchParams(qs).toString() // querystring encoding
			if(!force && loaded) return // prevent loading twice
			loaded = true
			set([])
			set((await api.load({url:fullurl})).data)
		},
		update:(updated_row)=>{
			update((rows)=>{
				let hasUpdated = false
				let updated = rows.map((row)=> row.id == updated_row.id ?	(hasUpdated = true && {...row,...updated_row}) :	row ) // dupe rows, update one if found
				if(!hasUpdated) updated.push(updated_row) // not found add new
				return updated
			})
		},
		remove:(id)=>{
			update((rows)=> rows.filter(r => r.id != id))
		}
	};
}

export const sections = createStore("/duocms/api/tracker/sections")
export const teams = createStore("/duocms/api/tracker/teams/me")
export const session = createStore("/duocms/api/users/me")
export const trackerusers = createStore("/duocms/api/tracker/users")