<span class="d-icon"><slot></slot></span>

<style>
  .d-icon{
    height:1em;
    display: inline-flex;
    align-self: center;
    position:relative;
    top:0.15em;
    -webkit-font-smoothing:antialiased;
  }
  .d-icon :global(svg){
    height:100%;
    shape-rendering: geometricPrecision;
  }
</style>