<script>import { Formrow, Button, IconSearch, api, IconCaretDown, IconSpinner, Dateformat, Pagination, Loadbar } from "duo-kit";
import { resources } from "./resource-store";
import { onMount } from "svelte";
export let student = false;
export let fields = "title,author,resource_type,organisation,resource_date";
export let perpage = 10;
export let category = null;
export let accesstype = null;
export let language = null;
export let resourcetype = null;
export let hide = "";
export let keyword = "";
export let author = null;
export let organisation = null;
export let fromyear = null;
export let toyear = null;
let search = {},
    resource_types = [],
    accesstypes = [],
    authors = [],
    organisations = [],
    results = [],
    loading,
    doc_languages = [],
    resource_dates = [],
    resource_years = [];
let total = 0;
let url = new URL(window.location.href);
let page = url.searchParams.get("from") ? url.searchParams.get("from") / 10 : 0;
let sort = url.searchParams.get("sort");
let order = url.searchParams.get("order");
let categories = [];
let loadWait = null;
resetSearch();
updateFromParams();

async function loadCategories() {
  let res = await api.load({
    url: `/duocms/api/resourcecenter/categories`
  });
  categories = res.data;
}

function updateFromParams() {
  if (category) {
    search.categories = category.split(",").map(str => +str);
  } else {
    search.categories = [];
  }

  if (accesstype) {
    search.accesstype = accesstype;
  }

  if (language) {
    search.doc_language = language;
  }

  if (resourcetype) {
    search.resource_type = resourcetype;
  }

  if (keyword != null) {
    search.keyword = keyword;
  }

  if (author != null) {
    search.author = author;
  }

  if (organisation != null) {
    search.organisation = organisation;
  }

  if (fromyear != null) {
    search.from_year = fromyear;
  }

  if (toyear != null) {
    search.to_year = toyear;
  }

  load();
} // debounces the load


async function load() {
  clearTimeout(loadWait);
  loadWait = setTimeout(dataload, 10);
}

async function dataload() {
  if (search.to_year && search.from_year > search.to_year) search.from_year = null;
  if (search.from_year && search.to_year < search.from_year) search.to_year = null;

  if (sort) {
    url.searchParams.set("sort", sort);
  } else {
    url.searchParams.delete("sort");
  }

  if (order) {
    url.searchParams.set("order", order);
  } else {
    url.searchParams.delete("order");
  }

  if (page) {
    url.searchParams.set("from", page * perpage);
  } else {
    url.searchParams.delete("from");
  }

  if (search.resource_type) {
    url.searchParams.set("resource_type", search.resource_type);
  } else {
    url.searchParams.delete("resource_type");
  }

  if (search.accesstype) {
    url.searchParams.set("accesstype", search.accesstype);
  } else {
    url.searchParams.delete("accesstype");
  }

  if (search.author != null) {
    url.searchParams.set("author", search.author);
  } else {
    url.searchParams.delete("author");
  }

  if (search.organisation != null) {
    url.searchParams.set("organisation", search.organisation);
  } else {
    url.searchParams.delete("organisation");
  }

  if (search.keyword) {
    url.searchParams.set("q", search.keyword);
  } else {
    url.searchParams.delete("q");
  }

  if (search.doc_language) {
    url.searchParams.set("doc_language", search.doc_language);
  } else {
    url.searchParams.delete("doc_language");
  }

  if (search.categories && search.categories.length) {
    url.searchParams.set("categories", search.categories.filter(v => v).join(","));
  } else {
    url.searchParams.delete("categories");
  }

  if (search.categories_multi && search.categories_multi.length) {
    url.searchParams.set("categories_multi", search.categories_multi.filter(v => v).join(","));
  } else {
    url.searchParams.delete("categories_multi");
  }

  if (search.doc_language) {
    url.searchParams.set("doc_language", search.doc_language);
  } else {
    url.searchParams.delete("doc_language");
  }

  if (search.doc_language) {
    url.searchParams.set("doc_language", search.doc_language);
  } else {
    url.searchParams.delete("doc_language");
  }

  if (search.from_year) {
    url.searchParams.set("from_year", search.from_year);
  } else {
    url.searchParams.delete("from_year");
  }

  if (search.to_year) {
    url.searchParams.set("to_year", search.to_year);
  } else {
    url.searchParams.delete("to_year");
  } // window.history.replaceState(null, null, url);
  // below params only sent to server, not shown in main url 


  url.searchParams.set("perpage", perpage);
  let urlparams = new URLSearchParams(url.searchParams.toString());

  if (student) {
    urlparams.set("student_access", true);
  } else {
    urlparams.delete("student_access");
  }

  if (fields) {
    urlparams.set("fields", fields);
  } else {
    urlparams.delete("fields");
  }

  if (loading && loading == urlparams.toString()) return; // prevent double load

  loading = urlparams.toString();
  await resources.loadAll({
    query: urlparams,
    force: true
  });
  loading = false;
  if (!$resources.fields) return;
  resource_types = $resources.fields.resource_type;
  accesstypes = $resources.fields.accesstype;
  authors = $resources.fields.author;
  organisations = $resources.fields.organisation;
  doc_languages = $resources.fields.doc_language; // resource_dates = $resources.fields.resource_date

  resource_years = $resources.fields.resource_years;
  ({
    results,
    sort,
    total
  } = $resources);
}

function resetCategories(length) {
  search.categories.length = length;
  load();
}

function resetSubCategories(length) {
  search.categories_multi.length = length;
  load();
}

function resetSearch() {
  search = {
    categories: [],
    categories_multi: [],
    keyword: "",
    resource_type: "",
    author: null,
    accesstype: "",
    organisation: null
  };
}

async function sortBy(val) {
  if (sort == val && order == "asc") {
    sort = null;
    order = null;
  } else if (sort == val && (!order || order == "desc")) {
    order = "asc";
  } else {
    order = "asc";
    sort = val;
  }

  load();
}

function open(path) {
  let params = new URLSearchParams(window.location.search);
  if (params.has("editmode")) return;
  window.location.href = path;
} // /duocms/api/resourcecenter/categories


$: page != null && load(page);

$: updateFromParams(student, category, accesstype, language, perpage, fields, resourcetype, keyword, author, organisation, fromyear, toyear);

$: hidefields = hide ? hide.split(",") : [];

loadCategories();</script>
  <div class="resource-center">
  {#if resource_types && accesstypes && authors}
    <form on:submit|preventDefault={load}>
      <div class="toolbar">
        {#if !hidefields.includes("keyword")}
          <Formrow placeholder="Keyword Search" type="text" bind:value={search.keyword}>
            <div slot="suffix"><Button mode="primary" type="submit"><IconSearch /></Button></div>
          </Formrow>
        {/if}
        
        {#if !hidefields.includes("resourcetype")}
          <Formrow type="select" bind:value={search.resource_type} on:change={load}>
              <option value="">All Resource Types</option>
              {#each resource_types as resource_type}
                <option value={resource_type} selected={resource_type == search.resource_type}>{resource_type}</option>
              {/each}
          </Formrow>
        {/if}
        
        {#if !hidefields.includes("accesstype")}
          <Formrow type="select" bind:value={search.accesstype} on:change={load}>
            <option value="">All Access Types</option>
            {#each accesstypes as accesstype}
              <option value={accesstype} selected={accesstype == search.accesstype}>{accesstype}</option>
            {/each}
          </Formrow>
        {/if}
        
        {#if !hidefields.includes("author")}
          <Formrow type="select" bind:value={search.author} on:change={load}>
            <option value={null}>All Authors</option>
            {#each authors as author}
              <option value={author.key} selected={author.key == search.author}>{author.value}</option>
            {/each}
          </Formrow>
        {/if}

        {#if !hidefields.includes("organisation")}
          <Formrow type="select" bind:value={search.organisation}  on:change={load}>
            <option>All Organisations</option>
            {#each organisations as organisation}
              <option value={organisation.key} selected={organisation.key == search.organisation}>{organisation.value}</option>
            {/each}
          </Formrow>
        {/if}
        {#if !["keyword","resourcetype","accesstype","author","organisation"].every(field => hidefields.includes(field) ) }
          <Button mode="primary" on:click={()=> resetSearch() && load()} >Reset</Button>
        {/if}
      </div>
    </form>
  {/if}
  {#if categories && !hidefields.includes("advanced")}
    <details open={search.categories[0] || search.doc_language || search.from_year || search.to_year} class="searchgrid-advanced">
      <summary>Advanced Search</summary>
      <div class="advsearchgrid">
        
        <div class="categories">
          <Formrow type="select" bind:value={search.categories[0]} on:change={()=> resetCategories(1)}>
            <option value="">All Categories</option>
            {#each categories as category}
              {#if  category.parent_id==0}
                <option value={category.id} selected={category.id==search.categories[0]}>{category.name}</option>
              {/if}
            {/each}
          </Formrow>

        {#if search.categories[0]}
          <Formrow type="select" bind:value={search.categories[1]} on:change={()=> resetCategories(2)}>
            <option value="" selected={!search.categories[1]}>All Sub-Categories</option>
            {#each categories as category}
              {#if  category.parent_id==search.categories[0]}
                <option value={category.id} selected={category.id==search.categories[1]}>{category.name}</option>
              {/if}
            {/each}
          </Formrow>
        {/if}
      
        {#if search.categories[1]}
          <Formrow type="select" bind:value={search.categories[2]} on:change={()=> resetCategories(3)}>
            <option value="" selected={!search.categories[2]}>All</option>
            {#each categories as category}
              {#if category.parent_id==search.categories[1]}
                <option value={category.id} selected={category.id==search.categories[2]}>{category.name}</option>
              {/if}
            {/each}
          </Formrow>
        {/if}
        <!-- additional filter -->
        {#if search.categories && search.categories[0]}
          <Formrow type="select" label="Filter by an additional category" vertical bind:value={search.categories_multi[0]} on:change={()=> resetSubCategories(1)}>
            <option value="">All</option>
            {#each categories as category}
              {#if category.parent_id==0}
                <option value={category.id} selected={category.id==search.categories_multi[0]}>{category.name}</option>
              {/if}
            {/each}
          </Formrow>
        {/if}
        {#if search.categories_multi[0]}
          <Formrow type="select" bind:value={search.categories_multi[1]} on:change={()=> resetSubCategories(2)}>
            <option value="">All</option>
            {#each categories as category}
              {#if category.parent_id==search.categories_multi[0]}
                <option value={category.id} selected={category.id==search.categories_multi[1]}>{category.name}</option>
              {/if}
            {/each}
          </Formrow>
        {/if}
        {#if search.categories_multi[1]}
          <Formrow type="select" bind:value={search.categories_multi[2]} on:change={()=> resetSubCategories(3)}>
            <option value="">All</option>
            {#each categories as category}
              {#if category.parent_id==search.categories_multi[1]}
                <option value={category.id} selected={category.id==search.categories_multi[2]}>{category.name}</option>
              {/if}
            {/each}
          </Formrow>
        {/if}
        
      </div>

        <Formrow type="select" bind:value={search.doc_language} on:change={load}>
          <option value="">All Languages</option>
          {#each doc_languages as doc_language}
            <option value={doc_language} selected={doc_language==search.doc_language}>{doc_language}</option>
          {/each}
        </Formrow>

        <Formrow type="select" bind:value={search.from_year} on:change={load}>
          <option value="">From Year</option>
          {#each resource_years as year}
            {#if !search.to_year || year < search.to_year }
              <option value={year} selected={year==search.from_year}>{year}</option>
            {/if}
          {/each}
        </Formrow>

        <Formrow type="select" bind:value={search.to_year} on:change={load}>
          <option value="">To Year</option>
          {#each resource_years as year}
            {#if !search.from_year || year > search.from_year }
              <option value={year} selected={year==search.to_year}>{year}</option>
            {/if}
          {/each}
        </Formrow>


      

      </div>
    </details>
  {/if}



  {#if results && results.length}
    <div class="loadbar"><Loadbar /></div>
    <div class="pagination_block">
      <p>{total} results, Showing Page {page+1} of {Math.ceil(total/10)}</p>
      <Pagination nextprev={true} max={10} perpage={perpage} bind:page data={[...new Array(total)]} />
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr class:flipcaret={order!="asc"}>
          {#if fields.split(",").includes("title")}<th on:click={()=> sortBy("title.raw")} style="width:80%">Title {#if sort=="title.raw"}<IconCaretDown />{/if}</th>{/if}
          {#if fields.split(",").includes("resource_type")}<th on:click={()=> sortBy("meta.resource_type")}>Type {#if sort=="meta.resource_type"}<IconCaretDown />{/if}</th>{/if}
          {#if fields.split(",").includes("author")}<th on:click={()=> sortBy("meta.author")}>Author {#if sort=="meta.author"}<IconCaretDown />{/if}</th>{/if}
          {#if fields.split(",").includes("organisation")}<th on:click={()=> sortBy("meta.organisation")}>Organisation {#if sort=="meta.organisation"}<IconCaretDown />{/if}</th>{/if}
          {#if fields.split(",").includes("resource_date")}<th on:click={()=> sortBy("meta.resource_date")} style="min-width:120px">Date {#if sort=="meta.resource_date"}<IconCaretDown />{/if}</th>{/if}
        </tr>
      </thead>
      <tbody>
        {#each results as result}
          <tr on:click={()=> open(result.path)}>
            {#if fields.split(",").includes("title")}<th>{result.title}</th>{/if}
            {#if fields.split(",").includes("resource_type")}<td>{result.type || ""}</td>{/if}
            {#if fields.split(",").includes("author")}<td class="pre">{result.author}</td>{/if}
            {#if fields.split(",").includes("organisation")}<td class="pre">{result.organisation}</td>{/if}
            {#if fields.split(",").includes("resource_date")}<td><Dateformat date={result.resource_date} format="dd MMM yyyy" /></td>{/if}
          </tr>
        {/each}
      </tbody>
    </table>
    <div class="pagination_block">
      <p>Found {total} results, Showing Page {page+1} of {Math.ceil(total/10)}</p>
      <Pagination nextprev={true} max={10} perpage={perpage} bind:page data={[...new Array(total)]} />
    </div>
  {/if}
  {#if !loading && !results.length}
    <p><strong>Sorry, we did not find anything, please try adjusting your search</strong></p>
  {/if}
  {#if loading && !results.length}
    <IconSpinner /> Loading Results...
  {/if}

</div>

<style>
  .resource-center{
    --s-border-radius:0px;
    --s-primary:#2d2e4e;
  }
  details{
    margin:10px 0;
  }
  .loadbar{
    background:#eee;
    height:4px;
    margin:20px 0;
  }

  .toolbar{
    display:flex;
    gap:5px;
  }
  .resource-center .toolbar > :global(*){
    flex:1;
    width:0;
    margin:0;
  }
  .resource-center .toolbar > :global(button){
    flex:0 0 60px
  }

  .table tbody tr {
    cursor:pointer;
  }
  .table th{
    color:#dd0000;
    font-weight:bold;
  }
  .pagination_block{
    display: flex;
    width: fit-content;
    margin: 20px auto;
    flex-direction: column;
    align-items: center;
  }

  .flipcaret :global(.d-icon){
    transform: scaleY(-1);
  }

  @media(max-width:992px){
    .resource-center .toolbar{
      flex-direction: column;
    }
    .resource-center .toolbar > :global(*){
      flex:auto;
      width:auto;
    }
    .resource-center .toolbar > :global(button){
      flex:1
    }
    .pagination_block :global(.spagination > .sbtn){
      padding-inline:0px;
      width: 30px;
    }
  }
</style>