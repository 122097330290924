
    var riot = require('riot')
    
riot.tag2('detailpicker', '<input type="hidden" name="{opts.name}" value="{value}"> <div if="{multiple}" each="{sel,index in selected}" class="detailrow"> <div class="input-group input-group-sm"><span class="form-control">{sel}</span> <div class="input-group-btn"> <button onclick="{parent.remove.bind(null,index)}" class="btn btn-danger"> <span class="fa fa-times"></span></button> </div> </div> </div> <div show="{showother}" class="detailrow"> <div class="input-group input-group-sm"> <input oninput="{handleOther}" value="{other}" type="text" placeholder="enter other here" class="form-control"> <div class="input-group-btn"> <button onclick="{add_other.bind(null,index)}" class="btn btn-success"> <span class="fa fa-check"></span></button> <button onclick="{remove_other.bind(null,index)}" class="btn btn-danger"> <span class="fa fa-times"></span></button> </div> </div> </div> <div class="input-group input-group-sm"> <select name="prop_select" class="form-control input-sm"> <option value="">Choose</option><yield></yield> <option value="">Other</option> </select> <div style="padding-left:2px" class="input-group-btn"> <button if="{opts.multiple}" onclick="{addOption}" style="border-radius:3px;" class="btn btn-primary btn-sm btn-block"> <span class="fa fa-plus"></span></button> </div> </div>', '', '', function(opts) {
this.showother = false;

this.other = "";

this.options = [];

this.selected = [];

this.multiple = false;

this.on('mount', function() {
  var ref;
  this.value = opts.value;
  this.selected = (ref = opts.value) != null ? ref.split(";") : void 0;
  if (!this.selected || this.selected[0] === '') {
    this.selected = [];
  }
  return this.update();
});

this.on('update', function() {
  if (opts.multiple != null) {
    return this.multiple = true;
  }
});

this.handleOther = (function(_this) {
  return function(e) {
    return _this.update({
      other: e.target.value
    });
  };
})(this);

this.add_other = (function(_this) {
  return function() {
    _this.selected.push(_this.other);
    _this.showother = false;
    _this.value = _this.selected.join(";");
    return _this.update();
  };
})(this);

this.remove_other = (function(_this) {
  return function() {
    _this.showother = false;
    return _this.update();
  };
})(this);

this.addOption = (function(_this) {
  return function() {
    var value;
    value = _this.prop_select.value;
    if (!value) {
      _this.other = "";
      _this.showother = true;
    } else {
      _this.selected.push(value);
      _this.prop_select.value = null;
      _this.value = _this.selected.join(";");
    }
    return _this.update();
  };
})(this);

this.remove = (function(_this) {
  return function(index) {
    _this.selected.splice(index, 1);
    return _this.value = _this.selected.join(";");
  };
})(this);

this.createOptions = (function(_this) {
  return function(data) {
    var retdata;
    if (!data) {
      return;
    }
    retdata = [];
    data.forEach(function(row) {
      return row.split(";").forEach(function(elem) {
        if (elem.length > 0) {
          return retdata.push(elem);
        }
      });
    });
    return retdata;
  };
})(this);
});
    
  