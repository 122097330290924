
require 'riot-kit/lib/routehandler'
require 'riot-kit/lib/raw'
require 'riot-kit/lib/confirm'
require 'riot-kit/lib/form'
require 'riot-kit/lib/fileinput'
require 'riot-kit/lib/loading'
require 'riot-kit/lib/popover'
require 'riot-kit/lib/toaster'


require './pse.tag'
require './pseapply.tag'
require './1_personal_details.tag'
require './2_referee_details.tag'
require './3_education_details.tag'
require './4_qualification_details.tag'
require './5_training_details.tag'
require './6_career_details.tag'
require './7_experience_details.tag'
require './8_competency_details.tag'
require './9_submit_details.tag'

