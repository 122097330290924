<script>import { Grid, api, Route, navigate, Search, toasterStore, Formrow, Modal, Button, Confirm, IconArrowDown, IconArrowUp } from "duo-kit";
import CompetenceEditModal from "./competence-editmodal.svelte";
import Resources from "./resources.svelte";
import Record from "./record.svelte";
import { session } from "./store.js";
export let id = null;
export let compid = null;
export let route;
let techarea = {};
let resources = [];
let filtered = [];
let selected = [];
let resources_id = null;
let tab = 0;
let editing = {};
let loading = false;
let savingOrder = false;

async function load() {
  loading = true;
  techarea = (await api.load({
    url: `/duocms/api/tracker/technicalareas/${id}`
  })).data; // below adds tech area as first competencie in list

  techarea.competencies.unshift({
    id: techarea.id,
    statement: techarea.title,
    teacharea: techarea.statement,
    code: techarea.code,
    achieved: techarea.achieved,
    area: "",
    level: ""
  });

  if (!route.startsWith(`/competencies/${id}/competence/`)) {
    // default to first in list if none chosen
    navigate(`/competencies/${id}/competence/${techarea.competencies[0].id}`);
  }

  loading = false;
}

async function select(e) {
  let suffix = "";
  if (route.match(/\/resources/)) suffix = "/resources";
  if (route.match(/\/record/)) suffix = "/record";
  navigate(`/competencies/${id}/competence/${e.detail[0].id}${suffix}`);
}

function recordSaved(e) {
  techarea.competencies = techarea.competencies.map(ta => {
    if (ta.id == e.detail.tracker_competency_id) ta.achieved = e.detail.achieved;
    return ta;
  });
}

async function handleTick(data) {
  let record = {
    issued_at: new Date(),
    tracker_competency_id: data.id,
    achieved: data.achieved,
    method: data.method || "Self evaluation"
  };
  if (data.record_id) record.id = data.record_id;
  let competency = (await api.save({
    url: `/duocms/api/tracker/record`,
    data: record
  })).data;
  toasterStore.add({
    title: "Saved",
    type: "success",
    message: "Saved Successfully"
  });
  recordSaved({
    detail: competency
  });
}

function updateSelected(compid, techarea) {
  if (compid && techarea.competencies && !selected.length) {
    selected = techarea.competencies.filter(row => +row.id == +compid);

    if (route.endsWith("edit")) {
      edit();
    }
  }
}

function edit() {
  editing = selected[0];
  navigate(`/competencies/${id}/competence/${editing.id}/edit`);
}

function add() {
  editing = {
    id: "new",
    parent_id: id,
    type: "Competence statement"
  };
  navigate(`/competencies/${id}/competence/${editing.id}/edit`);
}

function remove() {
  let compid = selected[0].id;
  api.remove({
    url: `/duocms/api/tracker/competencies/${compid}`
  });
  techarea.competencies = techarea.competencies.filter(item => item.id != compid);
  selected = [];
  navigate(`/competencies/${id}`);
  toasterStore.add({
    title: "Deleted",
    type: "success",
    message: "Competence Deleted Successfully"
  });
} // update list after saved


function competenceSaved(event) {
  let added = false;

  for (let key of Object.keys(techarea.competencies)) {
    if (techarea.competencies[key].id == event.detail.id) {
      techarea.competencies[key] = event.detail;
      added = true;
      break;
    }
  }

  if (!added) techarea.competencies = techarea.competencies.concat(event.detail);
}

async function order(delta) {
  // swap order of this entry, with the next one in `techarea.competencies`
  let fromIdx = techarea.competencies.indexOf(selected[0]);
  let from = {
    id: selected[0].id,
    order: selected[0].order + delta
  };
  let to = techarea.competencies.find(item => item.order == selected[0].order + delta);
  to = {
    id: to.id,
    order: to.order - delta
  };
  techarea.competencies[fromIdx].order += delta;
  techarea.competencies[fromIdx + delta].order -= delta;
  [techarea.competencies[fromIdx], techarea.competencies[fromIdx + delta]] = [techarea.competencies[fromIdx + delta], techarea.competencies[fromIdx]]; // then save both update to the server

  savingOrder = true;
  await Promise.all([api.save({
    url: `/duocms/api/tracker/competencies`,
    data: from
  }), api.save({
    url: `/duocms/api/tracker/competencies`,
    data: to
  })]);
  savingOrder = false;
}

function orderUp() {
  if (selected[0].order > 1) {
    // not first
    order(-1);
  }
}

function orderDown() {
  if (selected[0] != techarea.competencies[techarea.competencies.length - 1]) {
    // not last
    order(1);
  }
}

$: updateSelected(compid, techarea);

load();
session.load();</script>

{#if loading}
  <h3><span class="fa fa-refresh fa-spin" />  Loading...</h3>
{:else}
  <div class="competencies">
    <h2>{techarea.title}</h2>
      
   
    {#if $session && $session.permissions && $session.permissions.includes("pages_all")}
      <div class="edit-toolbar">
        <Button on:click={()=> navigate('/competencies/')}><span class="fa fa-arrow-left" /> Back to Technical Areas</Button>
        <Button mode="default" on:click={add}>Add</Button>
        <Button mode="default"  disabled={!selected.length} on:click={edit}>Edit</Button>
        <Confirm mode="default" disabled={!selected.length} on:confirm={remove}>Delete</Confirm>
        {#if filtered.length==techarea.competencies.length}
          <Button mode="default"  disabled={!selected.length || !selected[0].type || selected[0].order==1 || savingOrder} on:click={orderUp}><IconArrowUp /></Button>
          <Button mode="default"  disabled={!selected.length || !selected[0].type || selected[0].order>=techarea.competencies.length-1 || savingOrder} on:click={orderDown}><IconArrowDown /></Button>
        {/if}
      </div>
      <div class="searchbox">
        <Search placeholder="Search Competencies" bind:data={techarea.competencies} bind:filtered={filtered} />
      </div>
    {:else}
      <div class="searchbox">
        <Button on:click={()=> navigate('/competencies/')}><span class="fa fa-arrow-left" /> Back to Technical Areas</Button>
        <Search placeholder="Search Competencies" bind:data={techarea.competencies} bind:filtered={filtered} />
      </div>
    {/if}

    <div style={"height:250px"}>
      <Grid 
        rowheight={30} data={filtered} bind:selected on:click={select}
        columns={[
          // {label:"Order",field:"order",width:1},
          {label:"Code",field:"code",width:2},
          {label:"Statement",field:"statement",width:16},
          {label:"Area",field:"area",width:4},
          {label:"Level",field:"level",width:2},
          {label:"Achieved",field:"achieved",width:2}
        ]}
      > 
        <div slot="row" let:item>
          <!-- <div class="cell" style="flex:1" >{item.order||""}</div> -->
        <div class="cell" style="flex:2" >{item.code}</div>
        <div class="cell" style="flex:16;white-space:pre-wrap;text-overflow: ellipsis;height:90px;overflow:hidden;" >{item.statement}</div>
        <div class="cell" style="flex:4" >{item.area || ""}</div>
        <div class="cell" style="flex:2" >{item.level || ""}</div>
        <div class="cell" style="flex:2;text-align:center;" >{#if item.area}<input type="checkbox" checked={item.achieved} on:click={(e)=>handleTick({...item,achieved:e.target.checked})} />{/if}</div>
        </div> 
        <div slot="footer" let:data>
          {data.length} Items
        </div>
      </Grid>
    </div>

  
    <Route path="resources"><Resources id={id} /></Route>
    <Route path="record"><Record id={id} /></Route>

    <Route path="competence/:compid" let:params>
      <div class="row">
        <div class="col-xs-12">
          <ul class="nav nav-tabs" style="margin:10px 0;">   
            <li role="presentation" class:active={route && route == `/competencies/${id}/competence/${params.compid}` }>
              <a href="#!/competencies/{id}/competence/{compid}">{selected[0] && selected[0].type ? 'Competence Statement' : 'Technical Area'}</a>
            </li>
            {#if selected && selected[0] && selected[0].threshold}
              <li role="presentation" class:active={route && route.startsWith(`/competencies/${id}/competence/${params.compid}/threshold`)}>
                <a href="#!/competencies/{id}/competence/{compid}/threshold">Threshold Statement</a>
              </li>
            {/if}
            <li role="presentation" class:active={route && route.startsWith(`/competencies/${id}/competence/${params.compid}/resources`)}>
              <a href="#!/competencies/{id}/competence/{compid}/resources">Resource References</a>
            </li>
            <li role="presentation" class:active={route && route.startsWith(`/competencies/${id}/competence/${params.compid}/record`)}>
              <a href="#!/competencies/{id}/competence/{compid}/record">{selected[0] && selected[0].type ? 'Competence Record' : 'Technical Area Record'}</a>
            </li>
          </ul>
        </div>
      </div>
   
      <div class="tabpanel">
        <Route path="" exact>
          {#if selected.length}
            <div class="well">
              {#if selected[0].teacharea}
                <p class="pre">{selected[0].teacharea}</p>
              {:else}
                <p><strong>{selected[0].statement}</strong></p>
              {/if}
            </div>
            <ul>
              <li>Code: <strong>{selected[0].code}</strong></li>
              {#if selected[0].area}<li>Category: <strong>{selected[0].area}</strong></li>{/if}
              {#if selected[0].level}<li>NAFEMS Level: <strong>{selected[0].level}</strong></li>{/if}
            </ul>
          {/if}
        </Route>
        <Route path="threshold">
          {#if selected.length}
            <div class="well">
              {#if selected[0].teacharea}
                <p class="pre">{selected[0].teacharea}</p>
              {:else}
                <p><strong>{selected[0].statement}</strong></p>
              {/if}
            </div>
            <p class="pre threshold">{selected[0].threshold}</p>
        {/if}
        </Route>
        <Route path="resources"><Resources id={params.compid} /></Route>
        <Route path="record"><Record id={params.compid} on:saved={recordSaved} /></Route>

        <Route path="edit" let:route>
          {#if editing && editing.id}
            <CompetenceEditModal open={true} {route} parent={`/competencies/${id}/competence/${params.compid}`} bind:data={editing} on:saved={competenceSaved} />
          {/if}
        </Route>
      </div>
    </Route>
  </div>

  


{/if}

<style>
  .nav-tabs li{
    margin-bottom:-2px;
  }

  .competencies{
    position:relative;
  }
  .searchbox{
    position: relative;
  }
  .searchbox :global(.form-row){
    width:200px;
    position: absolute;
    right:0;
    bottom:2px;
  } 
  .threshold{
    padding:0 20px;
  }

  .tabpanel{
    position: relative;
  }
  .edit-toolbar{
    height:45px;
  }

</style>