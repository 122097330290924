<script>export let index = 0;
export let dayindex = 0;

function goto() {
  let container = document.querySelectorAll(".agenda")[+dayindex];
  let panel = container.querySelector("#room" + index);
  container.scrollLeft = panel.offsetLeft - 20;
}</script>

<button class="btn btn-primary" on:click|preventDefault={goto}><slot /></button>

<style>
  .btn:hover{
    background-color: #2d2e4e;
  }
</style>