<script>export let labels = "";

$: tabs = labels.split(/\|/);

let activeTab = 0;</script>

<div class="tabbuttons">
  {#each tabs as tab,idx }
    <button class:active={activeTab==idx} on:click={()=> activeTab=idx}>{tab}</button>
  {/each}
</div>

<div class="tabpanel">
  <div style={"display:" + ( activeTab == 0 ? "block" : "none" )}><slot name="tab1" /></div>
  <div style={"display:" + ( activeTab == 1 ? "block" : "none" )}><slot name="tab2" /></div>
  <div style={"display:" + ( activeTab == 2 ? "block" : "none" )}><slot name="tab3" /></div>
  <div style={"display:" + ( activeTab == 3 ? "block" : "none" )}><slot name="tab4" /></div>
  <div style={"display:" + ( activeTab == 4 ? "block" : "none" )}><slot name="tab5" /></div>
  <div style={"display:" + ( activeTab == 5 ? "block" : "none" )}><slot name="tab6" /></div>
  <div style={"display:" + ( activeTab == 6 ? "block" : "none" )}><slot name="tab7" /></div>
  <div style={"display:" + ( activeTab == 7 ? "block" : "none" )}><slot name="tab8" /></div>
  <div style={"display:" + ( activeTab == 8 ? "block" : "none" )}><slot name="tab9" /></div>
  <div style={"display:" + ( activeTab == 9 ? "block" : "none" )}><slot name="tab10" /></div>
</div>

<style>
  /* Hides webcomponent until it's loaded*/
  :global(duo-tabs:not(:defined)) {
    display:none
  }
  .tabbuttons button{
    background:#eee;
    font-size:14px;
    border:1px solid #eee;
    border-bottom:1px solid #ccc;
    padding:8px 12px;
    margin:0 2px -1px 0;
  }
  .tabpanel{
    border:1px solid #ccc;
    padding:15px;
  }
  .tabbuttons button.active{
    background:white;
    border:1px solid #ccc;
    border-bottom:1px solid white;
   
  }
  .tabbuttons button:focus{
    outline:0;
    border-top:1px solid #888;
  }


</style>
