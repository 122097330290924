<script>import { Grid, api, Dateformat, Confirm, IconTrash } from "duo-kit";
import { mycompetencies } from "./store.js";
import Record from "./record.svelte";
export let editable = false,
    showdelete = false;
let selected = [];
let selectedCompetence = [];
let report = [];
let techareas = [];
let competencies = [];
let showDetails = false;
let loading = false;
export let userid = "me";

async function load() {
  loading = true;
  report = (await api.load({
    url: "/duocms/api/tracker/reports/users/" + userid
  })).data;
  filterByTechArea();
  loading = false;
}

async function filterByTechArea() {
  techareas = report.filter(row => row.type == "technical area");

  if (techareas.length) {
    selected = [techareas[0]];
    showCompetencies();
  }
}

async function showCompetencies() {
  showDetails = false;
  competencies = report.filter(row => (+row.parent_id == +selected[0].id || +row.id == +selected[0].id) && row.record_id != null);
}

function selectCompetence() {
  if (editable) {
    showDetails = true;
  }
}

function recordSaved() {
  selectedCompetence = [];
  showDetails = false;
  load();
}

async function deleteCompetencie(record_id) {
  try {
    await api.remove({
      url: `/duocms/api/tracker/record/${record_id}`
    });
    report = report.filter(row => row.record_id != record_id); // remove from report after delete

    filterByTechArea();
  } catch (err) {}
}

load();</script>

{#if loading || techareas.length}
<div class="row">
  <div class="col-xs-12 col-md-3">
    <h3>Technical Areas</h3>
    <div style="height:500px">
      <Grid 
        rowheight={30} data={techareas} 
        bind:selected
        on:click={showCompetencies}
        columns={[
          {label:"Code",field:"code",width:5}
        ]}
      >
      <div slot="footer" let:data>
        {data.length} Technical Areas
      </div>
    </Grid>
    </div>
    <!-- {#if selected.length}
      <div class="panel panel-default" style="margin-top:10px;font-size:12px;">
        <div class="panel-heading" style="font-size:14px;padding:10px;">Technical Area</div>
        <table class="table table-striped">
          <tbody>
            <tr><th>Code</th><td>{selected[0].code}</td></tr>
            <tr><th>Title</th><td>{selected[0].title}</td></tr>
            {#if selected[0].achieved}
            <tr><td>Status</td><td>Achieved</td></tr>
            <tr><td>Achievement method</td><td>{selected[0].method || ""}</td></tr>
            <tr><td>Date</td><td><Dateformat format="dd/MM/yyyy" date={selected[0].issued_at} /></td></tr>
            {/if}
          </tbody>
        </table>
      </div>
    {/if} -->
  </div>
  <div class="col-xs-12 col-md-9">
    <h3>Competencies Claimed</h3>
    <div style="height:{showDetails && selectedCompetence[0] ? 250 : 500}px">
      <Grid 
        rowheight={50} data={competencies} 
        on:click={selectCompetence}
        bind:selected={selectedCompetence}
        columns={[
          {label:"Area",field:"area",width:2},
          {label:"Code",field:"code",width:1},
          {label:"Statement",field:"statement",width:7},
          {label:"Achieved",field:"achieved",width:1},
          {label:"Method",field:"method",width:2},
          {label:"Date",field:"issued_at",width:2},
        ].concat(showdelete ? [{label:"Delete",field:"id",width:1}] : [])}
      > 
        <div slot="row" let:item>
          <div class="cell" style="flex:2" >{item.area || "Technical Area"}</div>
          <div class="cell" style="flex:1" >{item.code}</div>
          <div class="cell" style="flex:7;white-space:pre-wrap;text-overflow: ellipsis;height:90px;overflow:hidden;" >{item.area ? item.statement : item.title}</div>
          <div class="cell" style="flex:1;text-align:center" >{#if item.achieved}<span class="fa fa-check" style="color:green" />{:else}<span class="fa fa-times" style="color:#888" />{/if}</div>
          <div class="cell" style="flex:2" >{item.method || ""}</div>
          <div class="cell" style="flex:2" >{#if item.issued_at}<Dateformat format="dd/MM/yyyy" date={item.issued_at} />{/if}</div>
          {#if showdelete}
            <div class="cell" style="flex:1"><Confirm mode="default" on:confirm={()=> deleteCompetencie(item.record_id)}><IconTrash /></Confirm></div>
          {/if}
        </div> 
        <div slot="footer" let:data>
          {data.length} Competencies
        </div>
      </Grid>
    </div>
    {#if showDetails && selectedCompetence[0]}
      <Record id={selectedCompetence[0].id} on:saved={recordSaved} {userid} />
    {/if}
  </div>
</div>
{:else}
  <br>
  <div class="alert alert-info">
    <h4>Sorry</h4>
    {#if userid=="me"}
      <p>You haven't recorded any competencies at this moment. </p>
      <a class="btn btn-default btn-sm" href="#!/competencies/">Click here to add some</a>
    {:else}
      <p>This user has not recorded any competencies. </p>
    {/if}
  </div>
{/if}