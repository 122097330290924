module.exports = (method,url,data,cback)=>
  req = new XMLHttpRequest()
  req.open(method, url, true)
  req.setRequestHeader("Content-type", "application/json")
  req.setRequestHeader("x-csrf-token", window.csrf)

  req.onreadystatechange = () =>
    if req.readyState == 4
      try 
        res = JSON.parse(req.responseText)
      catch 
        res = ""
      cback({status:req.status,body:res})
  console.log(method,url,data)
  if data then req.send(JSON.stringify(data)) else req.send()
