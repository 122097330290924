<script>import toasterStore from "../shared/toaster-store.js";
import { slide } from 'svelte/transition';
import * as api from "../shared/api.js";
import posts from "./store.js";
import Markdown from "../shared/markdown.svelte";
import Alert from "../shared/alert.svelte";
import Dateformat from "../shared/dateformat.svelte";
import Search from "../shared/search.svelte";
import Button from "../shared/button.svelte";
import Confirm from "../shared/confirm.svelte";
import Speaker from "../icons/speaker.svelte";
import Formrow from "../shared/formrow.svelte";
import Formdate from "../shared/formdate.svelte";
import Grid from "../shared/grid.svelte";
import Mappin from "../icons/mappin.svelte";
import Lock from "../icons/lock.svelte";
import SubscriberModal from "./subscriber-modal.svelte";
import FlagModal from "./flag-modal.svelte";
import { onMount } from "svelte";
import ForumSearch from "./forum-search.svelte";
import IconArrowUp from "../icons/arrowup.svelte";
import IconArrowDown from "../icons/arrowdown.svelte";
export let forumid;
export let userid = null;
export let permissions = "";
export let logintxt = "Login";
export let perpage = 10;
export let showlogin = null;
let editing = null;
let adding = null;
let saving = null;
let level = "categories";
let forum = null;
let category = null;
let toppost = null;
let params = new URLSearchParams(location.search);
let parent_id = params.get('post_id') || params.get('post');
let postpage = params.get('postpage') || 0;
let watched = [];
let newpost = null;
let totalpages = 0;
let loading = false;
let flagid = null;
let posters = [];
let groups = [];
let selectedGroups = [];
let filteredGroups = [];
let clipboard = [];

try {
  clipboard = JSON.parse(window.sessionStorage.getItem("forumclipboard")) || [];
} catch (err) {}

let socket;
let showSubscribers = false;

if (forumid && !parent_id) {
  parent_id = forumid;
  level = "categories";
}

if (showlogin) api.registerLogin(showlogin);
onMount(() => {
  socket = posts.setupSocket();
  socket.on("forum:addingpost", data => {
    if (data.user_id != userid) {
      // remove then add to avoid dupes
      posters = posters.filter(poster => poster.user_id != data.user_id && poster.parent_id != data.parent_id);
      posters = posters.concat(data);
    }
  });
  socket.on("forum:cancelledpost", data => {
    if (data.user_id != userid) {
      posters = posters.filter(poster => poster.user_id != data.user_id && poster.parent_id != data.parent_id);
    }
  });
  socket.on("forum:disconnected", data => {
    if (data.user_id != userid) {
      posters = posters.filter(poster => poster.user_id != data.user_id);
    }
  });
});

function postsByParent(parent_id, posts, postpage) {
  if (!posts || !posts.length) return [];
  let current = posts.filter(post => post.id == parent_id);
  let result = posts.filter(post => post.parent_id == parent_id);
  if (!current.length) return []; // this can happen when the permission for the current post is removed

  level = current[0].type == "forum" ? "categories" : current[0].type == "category" ? "posts" : "replies";
  toppost = null;
  category = null;
  forum = posts.filter(post => post.id == forumid)[0];

  if (level == "categories") {
    totalpages = 0;
    return result.sort((a, b) => a.order - b.order);
  } else if (level == "posts") {
    category = current[0];
    totalpages = Math.ceil(category.children / perpage);
    return result.filter(row => row.page == postpage).sort((a, b) => b.pinned ? b.order : b.created_at > a.created_at ? 1 : -1);
  } else {
    toppost = current[0];
    totalpages = Math.ceil(toppost.children / perpage);
    category = posts.filter(post => post.id == toppost.parent_id)[0];
    return result.filter(row => row.page == postpage).sort((a, b) => b.created_at < a.created_at ? 1 : -1);
  }
}

function open(newparent) {
  if (editing || adding) {
    toasterStore.add({
      type: "warning",
      title: "Sorry",
      message: "You can't click that while " + (adding ? "adding" : "editing") + " a post"
    });
    return;
  }

  let params = new URLSearchParams(location.search);
  params.set('post_id', newparent);
  postpage = 0;
  window.history.pushState({}, '', `${location.pathname}?${params}`);
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  parent_id = newparent;
}

function gotoPage(page) {
  if (editing || adding) {
    toasterStore.add({
      type: "warning",
      title: "Sorry",
      message: "You can't click that while " + (adding ? "adding" : "editing") + " a post"
    });
    return;
  }

  let params = new URLSearchParams(location.search);
  params.set('postpage', page);
  window.history.pushState({}, '', `${location.pathname}?${params}`);
  if (postpage != page) document.body.scrollTop = document.documentElement.scrollTop = 0;
  postpage = page;
} // function wrapping new post so when it gets set we can trigger some socket events


function isAdding(post) {
  if (!post) {
    adding = null;
    if (socket) socket.emit("forum:cancelledpost", {
      parent_id
    });
  } else {
    adding = true;
    if (socket) socket.emit("forum:addingpost", post);

    if (post.type == "category") {
      api.load({
        url: "/duocms/api/groups/"
      }).then(res => groups = res.data);
    }
  }

  return post;
}

async function toggleAlert(id) {
  if (watched.includes(id)) {
    await api.remove({
      url: "/duocms/api/forums-watched/" + id
    });
    toasterStore.add({
      type: "warning",
      title: "Unsubscribed",
      message: "You will no longer receive email notifications for this category"
    });
    watched = watched.filter(row => row != id);
  } else {
    await api.save({
      url: "/duocms/api/forums-watched/" + id,
      method: "PUT"
    });
    toasterStore.add({
      type: "success",
      title: "Subscribed",
      message: "You will now receive email notifications for all updates in this category"
    });
    watched = watched.concat(id);
  }
}

async function handleDelete(post) {
  posts.remove(post);
}

async function handleEdit(post) {
  if (newpost) return;
  editing = { ...post
  };
  editing.created_at = new Date(editing.created_at).toISOString().slice(0, -1);

  if (post.type == "category") {
    groups = (await api.load({
      url: "/duocms/api/groups/"
    })).data;
  }
}

function toggleWrite(item, group_id) {
  if (item.write_ids && item.write_ids.indexOf(group_id) > -1) {
    item.write_ids = item.write_ids.filter(id => id != group_id);
  } else {
    item.write_ids ? item.write_ids.push(group_id) : item.write_ids = [group_id];
  }

  return item;
}

function toggleRead(item, group_id) {
  if (item.read_ids && item.read_ids.indexOf(group_id) > -1) {
    item.read_ids = item.read_ids.filter(id => id != group_id);
  } else {
    item.read_ids ? item.read_ids.push(group_id) : item.read_ids = [group_id];
  }

  return item;
}

async function reorder({
  post,
  delta
}) {
  let order = post.order;
  $posts = $posts.map((row, idx) => {
    if (row.id == post.id) row.order += delta;
    if (row.parent_id == post.parent_id && row.order == order + delta) row.order -= delta;
    return row;
  });
  let data = {
    order: order + delta,
    id: post.id,
    parent_id: post.parent_id
  };
  await posts.save(data);
  reload();
}

function handleUp(post) {
  reorder({
    post,
    delta: -1
  });
}

function handleDown(post) {
  reorder({
    post,
    delta: 1
  });
}

async function handlePin(post, pinned) {
  post.pinned = pinned;
  await posts.save(post);
  reload();
}

function reload() {
  setTimeout(() => {
    posts.load({
      parent_id,
      postpage,
      perpage
    });
  }, 100); // slight delay to prevent double load by socket
}

function handleLock(post, locked) {
  post.locked = locked;
  posts.save(post);
}

async function handleUpdate(post) {
  saving = true;
  if (!post.read_ids) post.read_ids = [];
  if (!post.write_ids) post.write_ids = [];

  try {
    await posts.save(post);
    saving = false;
    editing = null;
  } catch (err) {
    saving = false; // re-enable save buttons if error
  }
}

async function handleSave() {
  saving = true;

  try {
    newpost.page = postpage;
    await posts.save(newpost);
    saving = false;
    newpost = isAdding(null);
  } catch (err) {
    saving = false; // re-enable save buttons if error
  }
}

async function load({
  parent_id,
  postpage,
  perpage,
  force
}) {
  loading = true;

  try {
    if (parent_id == forumid) {
      await posts.load({
        parent_id,
        perpage: 100,
        force
      }); // don't use pagination at top level
    } else {
      await posts.load({
        parent_id,
        postpage,
        perpage,
        force
      });
    }
  } catch (err) {
    console.error(err);
  }

  loading = false;
}

async function loadWatched(userid) {
  watched = (await api.load({
    url: "/duocms/api/forums-watched"
  })).data;
}

async function subscribeGroup() {
  console.log({
    selectedGroups
  });
  let names = selectedGroups.map(g => g.name).join(",");
  let group_ids = selectedGroups.map(g => g.id);
  await api.save({
    url: `/duocms/api/forums-watched/${editing.id}`,
    data: {
      group_ids
    }
  });
  toasterStore.add({
    type: "success",
    title: "Save",
    message: `The users from ${names} have been subscribed to this category`
  });
}

function cut(post) {
  clipboard = [post];
  window.sessionStorage.setItem("forumclipboard", JSON.stringify(clipboard));
}

function cancelcut() {
  clipboard = [];
  window.sessionStorage.removeItem("forumclipboard");
}

async function like(post_id) {
  await api.save({
    url: `/duocms/api/forums-like`,
    data: {
      id: post_id
    }
  });
  load({
    parent_id,
    postpage,
    perpage,
    force: true
  });
}

async function unlike(post_id) {
  await api.remove({
    url: `/duocms/api/forums-like/${post_id}`
  });
  load({
    parent_id,
    postpage,
    perpage,
    force: true
  });
}

async function pastepost() {
  if (level != "posts") {
    toasterStore.add({
      type: "warning",
      title: "Sorry",
      message: "You can't paste here, please go to a top level category"
    });
    return;
  }

  if (parent_id != clipboard[0].parent_id) {
    let newpost = {
      id: clipboard[0].id,
      parent_id: parent_id
    };
    await posts.save(newpost);
    reload();
  }

  clipboard = [];
}

window.addEventListener("popstate", e => {
  params = new URLSearchParams(location.search);
  parent_id = params.get('post_id') || params.get('post') || forumid;
  document.body.scrollTop = document.documentElement.scrollTop = 0;
});

$: parent_id && load({
  parent_id,
  postpage,
  perpage
});

$: post_by_parent = postsByParent(parent_id, $posts, postpage);

$: userid && loadWatched(userid);</script>

<div class="forum">
  {#if !forumid}
    <div class="alert alert-danger">
      No Forum Id provided. Please select or create forum in cms toolbar.
    </div>
  {:else}
    {#if forum}
      <h2>{forum.title}</h2>
    {/if}

    <ul class="sbreadcrumb">
      <li><button on:click={(e)=> open(forumid)}>Categories</button></li>
      {#if category && category.title}<li><button class="btn btn-link" on:click={(e)=> open(category.id)}>{category.title}</button></li>{/if}
      {#if toppost && toppost.title}<li><button class="btn btn-link">{toppost.title}</button></li>{/if}
    </ul>

    {#if userid && !loading}
      <ForumSearch parentid={forumid} on:selected={(e)=> open(e.detail)}/>
    {/if}

    {#if clipboard.length}
      <Alert mode="info">
        <p>
          You have cut the post, "{clipboard[0].title}". Navigate to the category you wish to move the post to then <Button mode="info" size="sm" on:click={pastepost}>click here to paste</Button><br>
          If you no longer wish to move the post, <Button mode="info" size="sm" on:click={cancelcut}>click here to cancel</Button>
        </p>
      </Alert>
    {/if}

    {#if category}
      <div class="post" transition:slide>
        <h3 on:click={(e)=> open(category.id)}>{category.title}</h3>
        {#if !toppost}<Markdown value={category.body} readonly />{/if}
      </div>
    {/if}

    {#if toppost}
      <div class="post toppost" transition:slide>

        {#if editing && toppost.id == editing.id}
          {#if saving}<div style="float:right;padding:10px;"><span class="spinner" /> Saving... </div>{/if}
          <h3>Edit Post</h3>
          <Formrow label="Title" id="titleInput" type="text" name="title" bind:value={editing.title} placeholder="Category Title"></Formrow>
          <Formrow label="Description" name="body" id="descriptionInput">
            <Markdown id="descriptionInput" bind:value={editing.body} bind:files={editing.files}/>
          </Formrow>
          {#if permissions.includes("forum_update_posts")}
            <Formrow label="Created At (UTC)" type="datetime-local" name="created_at" bind:value={editing.created_at} placeholder=""></Formrow>
          {/if}
          <div class="toolbar">
            <Button mode="default" disabled={saving} on:click={(e)=> editing = null}>Cancel</Button>
            <Button mode="dark" disabled={saving} on:click={(e)=> handleUpdate(editing)}>Save</Button>
          </div>
        {:else}
          <div class="edit">
            {#if permissions.includes("forum_update_posts") || (permissions.includes("forum_update_own_posts") && userid==toppost.user_id) }
              <Button  size="xs" on:click={(e)=> handleEdit(toppost)}>Edit</Button>
            {/if}  
            {#if userid}
              <Button size="xs" on:click={()=> flagid = toppost.id}>Flag</Button>
              {#if toppost.like_user_ids && toppost.like_user_ids.includes(+userid)}
                <Button size="xs" on:click={()=> unlike(toppost.id)}>Unlike</Button>
              {:else}
                <Button size="xs" on:click={()=> like(toppost.id)}>Like</Button>
              {/if}
            {/if}
          </div>
          <h3 on:click={(e)=> open(toppost.id)}>{toppost.title || ""}</h3>
          <Markdown value={toppost.body} readonly />
          <div class="details">
            {toppost.author_first_name} {toppost.author_surname}
            {#if toppost.author_orgname}
              from {toppost.author_orgname}
            {/if}
            -
            {toppost.children} {toppost.children == 1 ? 'Reply' : 'Replies'},
            Last Updated <Dateformat date={new Date(toppost.latest_at || toppost.updated_at)} format="dd MMM yyyy hh:mm"></Dateformat>
            - {toppost.likes_total} Like{#if toppost.likes_total!=1}s{/if} 
          </div>
        {/if}

      </div>
    {/if}

    {#each post_by_parent as post,post_idx (post.id)}
      <div class="post" class:reply={post.type=="reply"} transition:slide>

      {#if level=="categories"}
        {#if editing && post.id == editing.id}
          {#if saving}<div style="float:right;padding:10px;"><span class="spinner" /> Saving... </div>{/if}
          <h3>Edit Category</h3>
          <Formrow label="Title" id="titleInput" type="text" name="title" bind:value={editing.title} placeholder="Category Title"></Formrow>
          <Formrow label="Description" id="descriptionInput" name="body">
            <Markdown id="descriptionInput" bind:value={editing.body} bind:files={editing.files}/>
          </Formrow>
          {#if groups && groups.length}
            <Formrow label="Permissions" name="body">
              <Search bind:data={groups} bind:filtered={filteredGroups} placeholder="Search Groups" />
              <div class="permissions-grid" style="height:200px">
                <Grid data={filteredGroups} rowheight={40} orderby="name" bind:selected={selectedGroups} columns={[
                  {label:"Group Name",field:"name",width:10},
                  {label:"Read",field:"read",width:1},
                  {label:"Write",field:"write",width:1}
                ]}>
                  <div slot="row" let:item>
                    <div style="flex:10;" class="cell">{item.name}</div>
                    <div style="flex:1;text-align:center" class="cell"><input type="checkbox" on:click={(e)=> editing = toggleRead(editing,item.id)} checked={editing.read_ids && editing.read_ids.indexOf(item.id)>-1} ></div>
                    <div style="flex:1;text-align:center" class="cell"><input type="checkbox" on:click={(e)=> editing = toggleWrite(editing,item.id)} checked={editing.write_ids && editing.write_ids.indexOf(item.id)>-1} ></div>
                  </div>
                </Grid>
              </div>
            </Formrow>
          {/if}
          <div style="float:right">
            <Button mode="primary" on:click={subscribeGroup} disabled={!selectedGroups.length} size="sm">Subscribe Selected Group To Updates </Button>
          </div>
          <Formrow label="Publish" name="published" type="checkbox" bind:checked={editing.published}></Formrow>
          <Formrow label="Public Posts" name="public" type="checkbox" bind:checked={editing.public}></Formrow>
          <div style="margin-left:165px;"><small>Public posts allows everyone to view posts, but normal permissions apply to replies</small></div>
          <div class="toolbar">
            <Button mode="default" disabled={saving} on:click={(e)=> editing = null}>Cancel</Button>
            <Button mode="dark" disabled={saving} on:click={(e)=> handleUpdate(editing)}>Save</Button>
          </div>
        {:else}
          <div class="edit">
            {#if userid}
              <Button size="xs" aria-label="toggle watching" role="switch" aria-checked={watched.includes(post.id) ? "true" : "false"} title={watched.includes(post.id) ? "Click to stop notification" : "Click to Notify Me Of Updates"} on:click={(e)=> toggleAlert(post.id)} ><Speaker on={watched.includes(post.id)} /></Button>
            {/if}
            {#if permissions.includes("forum_delete_categories")}
              <Confirm  size="xs" mode="default" on:confirm={(e)=> handleDelete(post)} placement="left">Delete</Confirm>
            {/if}
            {#if permissions.includes("forum_update_categories")}
              <Button size="xs" on:click={(e)=> showSubscribers = post.id}>View Subscribers</Button>
              <Button size="xs" on:click={(e)=> handleEdit(post)}>Edit</Button>
              <Button size="xs" aria-label="Reorder Up" disabled={post.order==1} on:click={(e)=> handleUp(post)}><IconArrowUp /></Button>
              <Button size="xs" aria-label="Reorder Down" disabled={post.order==post_by_parent.length} on:click={(e)=> handleDown(post)}><IconArrowDown /></Button>
            {/if}
          </div>

          <div class="clickinto" on:click={(e)=> open(post.id)} >
            {#if post.title}<h3>{post.title}</h3>{/if}
            {#if !post.published} <div class="unpublished">unpublished</div>{/if}
            <Markdown value={post.body} readonly />
          </div>
          <div class="details">
            {post.children} {post.children == 1 ? 'Post' : 'Posts'},
            {post.grandchildren} {post.grandchildren == 1 ? 'Reply' : 'Replies'},
            Last Updated <Dateformat date={new Date(post.latest_at || post.updated_at)} format="dd MMM yyyy hh:mm"></Dateformat>
          </div>
        {/if}

      {:else if level=="posts"}
        {#if editing && post.id == editing.id}
          {#if saving}<div style="float:right;padding:10px;"><span class="spinner" /> Saving... </div>{/if}
          <h3>Edit Post</h3>
          <Formrow label="Title" id="titleInput" type="text" name="title" bind:value={editing.title} placeholder="Category Title"></Formrow>
          <Formrow label="Description" id="descriptionInput" name="body">
            <Markdown id="descriptionInput" bind:value={editing.body} bind:files={editing.files}/>
          </Formrow>
          <div class="toolbar">
            <Button mode="default" disabled={saving} on:click={(e)=> editing = null}>Cancel</Button>
            <Button mode="dark" disabled={saving} on:click={(e)=> handleUpdate(editing)}>Save</Button>
          </div>
        {:else}
          <div class="edit">
            {#if userid }
              <Button size="xs" aria-label="toggle watching" role="switch" aria-checked={watched.includes(post.id) ? "true" : "false"} disabled={watched.includes(category.id)} title={watched.includes(category.id) ? "You are being notified of all changes to this category" : watched.includes(post.id) ? "Click to stop notification" : "Click to Notify Me Of Updates"} on:click={(e)=> toggleAlert(post.id)} ><Speaker on={watched.includes(post.id) || watched.includes(category.id)} /></Button>
            {/if}
            {#if permissions.includes("forum_update_categories") && post.locked}
              <Button  size="xs" on:click={(e)=> handleLock(post,false)}>Unlock</Button>
            {/if}
            {#if permissions.includes("forum_update_categories") && !post.locked}
              <Button  size="xs" on:click={(e)=> handleLock(post,true)}>Lock</Button>
            {/if}
            {#if permissions.includes("forum_update_categories") && post.pinned}
              <Button  size="xs" on:click={(e)=> handlePin(post,false)}>Unpin</Button>
              <Button size="xs" disabled={post.order==1} on:click={(e)=> handleUp(post)}><IconArrowUp /></Button>
              <Button size="xs" disabled={post.order==post_by_parent.length} on:click={(e)=> handleDown(post)}><IconArrowDown /></Button>
            {/if}
            {#if permissions.includes("forum_update_categories") && !post.pinned}
              <Button  size="xs" on:click={(e)=> handlePin(post,true)}>Pin</Button>
            {/if}
            {#if permissions.includes("forum_update_posts")}
              <Button  size="xs" on:click={(e)=> cut(post,true)}>Cut</Button>
            {/if}
            {#if permissions.includes("forum_delete_posts") || (permissions.includes("forum_delete_own_posts") && userid==post.user_id && post.children==0) }
              <Confirm  size="xs"  mode="default" on:confirm={(e)=> handleDelete(post)} placement="left">Delete</Confirm>
            {/if}
            {#if permissions.includes("forum_update_posts") || (permissions.includes("forum_update_own_posts") && userid==post.user_id) }
              <Button  size="xs" on:click={(e)=> handleEdit(post)}>Edit</Button>
            {/if}  
          </div>
          <div class="clickinto" on:click={(e)=> open(post.id)} >    
            <h4>{post.title}</h4>
          </div>
          <div class="details">
            {post.children} {post.children == 1 ? 'Reply' : 'Replies'},
            Post by {post.author_first_name} {post.author_surname} 
            {#if post.author_orgname && post.children == 0}
              from {post.author_orgname} 
            {/if}
            <Dateformat date={new Date(post.created_at)} format="dd MMM yyyy h:mm"></Dateformat>
            {#if post.children > 0}
              , Most Recent Reply By {post.latest_first_name} {post.latest_surname} <Dateformat date={new Date(post.latest_at)} format="dd MMM yyyy h:mm"></Dateformat>
            {/if}
            {#if post.pinned}<span style="display:inline-block;vertical-align:top;margin:2px 0px -5px 5px"><Mappin /></span>{/if}
            {#if post.locked}<span style="display:inline-block;vertical-align:top;margin:2px 0px -5px 5px"><Lock /></span>{/if}
          </div>
        {/if}
      {:else}
        <!-- Replies -->
        {#if editing && post.id == editing.id}
          {#if saving}<div style="float:right;padding:10px;"><span class="spinner" /> Saving... </div>{/if}
          <h3>Edit Reply</h3>
          <Formrow name="body">
            <Markdown bind:value={editing.body} bind:files={editing.files} placeholder="your reply" />
          </Formrow>
          <div class="toolbar">
            <Button mode="default" disabled={saving} on:click={(e)=> editing = null}>Cancel</Button>
            <Button mode="dark" disabled={saving} on:click={(e)=> handleUpdate(editing)}>Save</Button>
          </div>
        {:else}
          <div class="edit">
            {#if permissions.includes("forum_delete_posts") || (permissions.includes("forum_delete_own_posts") && userid==post.user_id && post_idx==post_by_parent.length-1) }
              <Confirm  size="xs"  mode="default" on:confirm={(e)=> handleDelete(post)} placement="left">Delete</Confirm>
            {/if}
            {#if permissions.includes("forum_update_posts") || (permissions.includes("forum_update_own_posts") && userid==post.user_id) }
              <Button  size="xs" on:click={(e)=> handleEdit(post)}>Edit</Button>
            {/if}
            {#if userid}
              <Button size="xs" on:click={()=> flagid = post.id}>Flag</Button>
              {#if post.like_user_ids && post.like_user_ids.includes(+userid)}
              <Button size="xs" on:click={()=> unlike(post.id)}>Unlike</Button>
              {:else}
                <Button size="xs" on:click={()=> like(post.id)}>Like</Button>
              {/if}
            {/if}
          </div>
          <div>
            <Markdown bind:value={post.body} readonly></Markdown>
          </div>
          <div class="details">
            Reply by {post.author_first_name} {post.author_surname} 
            {#if post.author_orgname} from {post.author_orgname} {/if}
              - <Dateformat date={new Date(post.created_at)} format="dd MMM yyyy h:mm"></Dateformat>
              - {post.likes_total} Like{#if post.likes_total!=1}s{/if} 
          </div>
        {/if}
      {/if}
      </div>
    {/each}
    {#each posters as poster}
      {#if poster.parent_id == parent_id}
        <div class="post" transition:slide>
          {poster.first_name} {poster.surname} is typing...
        </div>
      {/if}
    {/each}

    <!-- Loading -->
    {#if loading}
      <p><span class="spinner" /> Loading... </p>
    {/if}

    <!-- pagination -->
    {#if totalpages > 1 && !adding && !editing}
      <div>
        {#each [...Array(totalpages)].map((_,i) => i) as num}
          <Button mode={num==postpage ? "dark" : ""} on:click={(e)=> gotoPage(num)}>{num+1}</Button>
        {/each}
        <!-- Page {+replypage+1}  of {totalpages} ({post.total} Replies) -->
      </div>
    {/if}

    <!-- footer buttons -->
    {#if !userid}
      {#if showlogin}<Button mode="dark" on:click={showlogin}>{logintxt}</Button>{/if}
    {:else if newpost}
      <div class="post">
        {#if saving}<div style="float:right;padding:10px;"><span class="spinner" /> Saving... </div>{/if}
        <h3>{level=="categories" ? "Add Category" : level=="posts" ? "Add Post" : "Add Reply"}</h3>
        {#if level=="categories" || level=="posts"}
          <Formrow label="Title" id="titleInput" type="text" name="title" autofocus bind:value={newpost.title} placeholder="Category Title"></Formrow>
        {/if}
        <Formrow label={level=="categories" || level=="posts" ? "Description" : null} id="descriptionInput" name="body">
          <Markdown id="descriptionInput" bind:value={newpost.body} autofocus={level=="replies" ? true : null } bind:files={newpost.files} />
        </Formrow>
        {#if level=="categories"}
          <Formrow label="Permissions" name="body">
            <div class="permissions-grid" style="height:200px">
              <Grid data={groups} rowheight={40} columns={[{label:"Group Name",field:"id",width:10},{label:"Can Read",field:"name",width:1},{label:"Can Write",field:"name",width:1}]}>
                <div slot="row" let:item>
                  <div style="flex:10;" class="cell">{item.name}</div>
                  <div style="flex:1;text-align:center" class="cell"><input type="checkbox" on:click={(e)=> newpost = toggleRead(newpost,item.id)} checked={newpost.read_ids && newpost.read_ids.indexOf(item.id)>-1} ></div>
                  <div style="flex:1;text-align:center" class="cell"><input type="checkbox" on:click={(e)=> newpost = toggleWrite(newpost,item.id)} checked={newpost.write_ids && newpost.write_ids.indexOf(item.id)>-1} ></div>
                </div>
              </Grid>
            </div>
          </Formrow>
          <Formrow label="Publish" name="published" type="checkbox" bind:checked={newpost.published}></Formrow>
        {/if}
        <div class="toolbar">
          <Button mode="default" disabled={saving} on:click={(e)=> newpost = isAdding(null)}>Cancel</Button>
          <Button mode="dark" disabled={saving} on:click={handleSave}>Save</Button>
        </div>
      </div>
    {:else if !editing || !editing.id}
      {#if permissions.includes("forum_update_categories") && !editing && !newpost && level=="categories"}
        <Button mode="dark" on:click={(e)=> newpost=isAdding({type:"category",files:[],parent_id:parent_id})}>Add Category</Button>
      {/if}
      {#if permissions.includes("forum_add_posts") && !editing && !newpost && level=="posts"}
        <Button mode="dark" disabled={!category.writeable} on:click={(e)=> newpost=isAdding({type:"post",files:[],parent_id:parent_id}) }>Add Post</Button>
        {#if !category.writeable}
          Sorry, you don't have permission to post to this category
        {/if}
      {/if}
      {#if permissions.includes("forum_add_posts") && toppost && !toppost.locked && !newpost && level=="replies"}
        <Button mode="dark" disabled={!category.writeable} on:click={(e)=>{ 
          newpost=isAdding({type:"reply",files:[],parent_id:parent_id})
          postpage=Math.floor(toppost.children/perpage)
        }}>Reply</Button>
        {#if !category.writeable}
          Sorry, you don't have permission to reply to posts within this category
        {/if}
      {/if}
      {#if permissions.includes("forum_add_posts") && toppost && toppost.locked && !newpost && level=="replies"}
        <div class="alert alert-info">
          This post has been locked, no further replies can be added.
        </div>
      {/if}
    {/if}
  {/if}
</div>

{#if showSubscribers}
  <SubscriberModal bind:open={showSubscribers} postid={showSubscribers} on:closed={()=> showSubscribers=null}/>
{/if}
<FlagModal open={flagid!=null} bind:postid={flagid} />

<style>
  .sbreadcrumb{
    padding:0;
    margin:0;
    background:white;
    border:2px solid #eee;
    margin-bottom:20px;
    list-style:none;
  }
  .sbreadcrumb li{
    margin:0;
    display:inline-block;
  }

  .sbreadcrumb button{
    border:0px;
    padding:6px 12px;
    font-size:16px;
    line-height:1.5;
    cursor:pointer;
    color:#555;
    background:transparent;
  }

  .sbreadcrumb > li + li:before{
    content: '/\00a0';
    padding: 0 5px;
    color: #ccc;
  }

  .forum{
    position:relative;
  }

  .forum :global(.edit){
    position:absolute;
    bottom:2px;
    right:5px;
  }
  .forum :global(.edit button){
    margin:0
  }
  .forum :global(img[alt=icon]){
    float:left;
    max-width:100px;
    margin:0 10px 10px 0;
  }
  .forum :global(.preview){
    overflow:hidden; /* prevent float on icon cause overlap */
  }
  .post{
    border:2px solid #eee;
    background:white;
    padding:10px 15px;
    margin:10px 0;
    transition:0.5s border;
    position: relative;
  }

  .clickinto{
    cursor:pointer;
  }

  .post:hover{
    border:2px solid #aaa;
  }

  .post:hover .details{
    background:#ddd;
  }
  .details,.toolbar{
    background:#eee;
    padding:5px 15px;
    margin:10px -15px -10px -15px;
    font-size:12px;
    transition:0.5s background;
    
  }
  .toolbar{
    text-align:right;
  }
  .unpublished{
    position:absolute;
    top:5px;
    right:5px;
    pointer-events: none;
    padding:3px 8px;
    font-size:12px;
    border:1px solid #ddd;
    border-radius: 3px;
  }
  .spinner{
    border:4px solid #444;
    border-color:rgba(0,0,0,0.5) rgba(0,0,0,0.3) rgba(0,0,0,0.2) rgba(0,0,0,0.1);
    border-radius:50%;
    width:18px;
    height:18px;
    display:inline-block;
    animation:spin 1s infinite linear;
    margin-bottom:-4px;
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
</style>